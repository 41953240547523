import React from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';
import useWindowSize from '../../common/useWindowSize';
import { mobileWidth } from '../../../utils/css/sizes';

interface IProps {
  showHomePageSection?: boolean;
  showBackToHomePage?: boolean;
}

const Header: React.FC<IProps> = (props) => {
  const [width] = useWindowSize();
  return width <= mobileWidth ? (
    <Mobile {...props}/>
  ) : (
    <div style={{ flex: 1 }}>
      <Desktop {...props} />
    </div>
  );
};

export default Header;
