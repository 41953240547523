import styled from "styled-components";
import { IMarkerProps_v2 } from "../../../../../interfaces/common";
import useDetectClickOutside from "../../../useDetectClickOutside";
import React from "react";
import marker from "../../../../../assets/icons/marker.png";
import markerHome from "../../../../../assets/icons/markerHome.svg";
import centeredSalonMarker from "../../../../../assets/icons/centeredSalonMarker.svg";
import Svg from "../../../Svg/Svg";
import MarkerInformation from "../MarkerInformation";

const StyledMarker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  white-space: pre;
  cursor: pointer;
`;

export const UserMarker: React.FC<{ lat: number; lng: number }> = () => (
  <StyledMarker>
    <Svg alt="marker" src={markerHome} />
  </StyledMarker>
);

export const CenteredMarker: React.FC<{ lat: number; lng: number }> = () => (
  <StyledMarker>
    <Svg alt="marker" src={centeredSalonMarker}  />
  </StyledMarker>
)

export const Marker: React.FC<IMarkerProps_v2> = ({
  markerInfo,
  onMarkerClick,
  isCenter,
  setSideDrawerOpen
}) => {
  const { isVisible, wrapperRef, toggleIsVisible } = useDetectClickOutside();

  return (
    <>
      <div ref={wrapperRef} onClick={(e) => e.stopPropagation()}>
        <StyledMarker
          onClick={() => {
            onMarkerClick();
            toggleIsVisible();
          }}
        >
          <Svg alt="marker" src={isCenter ? centeredSalonMarker : marker} />
        </StyledMarker>
        {isVisible && <MarkerInformation {...markerInfo} setSideDrawerOpen={setSideDrawerOpen} />}
      </div>
    </>
  );
};
