import { useTranslation } from 'react-i18next';
import { adminActionTypes } from '../actions/admin/admin.types';

export interface IAdminState {
  initDataLoading: boolean;
  cities: unknown[];
  communities: unknown[];
  languages: unknown[];
  org_types: { id: string; label: string }[];
  error: unknown;
  newWitnessId: string;
  duplicateWitnessNames: string[];
  volunteerList: unknown[];
  salondays: any[];
  userCity: string;
  numOfActiveFilters: unknown;
  additionalPrograms: string[];
  hostSalonTraining: {
    date: string;
    full_address: string;
    id: number;
    time: string | Date;
  }[];
}

const initialState: IAdminState = {
  initDataLoading: null,
  cities: [],
  communities: [],
  languages: [],
  org_types: [
    { id: "Youth movement", label: "תנועת נוער" },
    { id: "Israel Prison Service", label: "שב״ס" },
    { id: "Team sports", label: "קבוצת ספורט" },
    { id: "Clinics and hospitals", label: "מרפאות ובתי חולים" },
    { id: "Boarding school", label: "פנימיות" },
    { id: "Youth sponsorship", label: "חסות הנוער" },
    { id: "Pre Army Service Year", label: "שנת שירות" },
    { id: "Mechina", label: "מכינה" },
    { id: "Embassies", label: "שגרירויות" },
    { id: "Local government", label: "רשות מקומית" },
    { id: "Students", label: "סטודנטים" },
    { id: "Voluntary association", label: "עמותה" },
    { id: "Womens organizations", label: "ארגוני נשים" },
    { id: "Museums", label: "מוזיאונים" },
    { id: "IDF", label: "צה״ל" },
    { id: "Cafe or restaurant", label: "בית קפה או מסעדה" },
    { id: "Government organization", label: "ארגון ממשלתי" },
    { id: "Bank", label: "בנק" },
    { id: "Culture Organization", label: "מוסדות תרבות" },
    { id: "Community centers", label: "מתנ״סים ומרכזים קהילתיים" },
    { id: "Office", label: "משרד" },
    { id: "School", label: "בית ספר" },
    { id: "High-tech", label: "חברת הייטק" },
    { id: "Other", label: "אחר" },
  ],
  error: "",
  newWitnessId: "",
  duplicateWitnessNames: [],
  volunteerList: [],
  salondays: [],
  userCity: "",
  numOfActiveFilters: "",
  additionalPrograms: [],
  hostSalonTraining: [],
};

const adminReducer = (state = initialState, action: any): IAdminState => {
  switch (action.type) {
    case adminActionTypes.GET_INIT_DATA_START:
    case adminActionTypes.GET_APP_DATA_START: {
      return { ...state, initDataLoading: true };
    }
    case adminActionTypes.GET_USER_CITY_ADDRESS: {
      return { ...state, userCity: action.payload };
    }
    case adminActionTypes.CLEAR_USER_CITY_ADDRESS: {
      return { ...state, userCity: '' };
    }
    case adminActionTypes.GET_INIT_DATA_SUCCESS: {
      const { cities, communities, languages } = action.payload;
      const translatedCommunities = communities.map(({ id, name }) =>
        id === 1 && name === 'No Community'
          ? { id, name: 'ללא קהילה' }
          : { id, name }
      );
      return {
        ...state,
        cities,
        languages,
        communities: translatedCommunities,
        initDataLoading: false,
      };
    }
    case adminActionTypes.GET_APP_DATA_SUCCESS: {
      const { salondays } = action.payload;
      return { ...state, salondays };
    }

    case adminActionTypes.GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload,
      };
    }
    case adminActionTypes.GET_CITIES_FAILURE: {
      return {
        ...state,
        cities: action.payload,
      };
    }
    case adminActionTypes.GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communities: action.payload,
        error: '',
      };
    case adminActionTypes.GET_COMMUNITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case adminActionTypes.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload,
        error: '',
      };
    case adminActionTypes.GET_LANGUAGES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case adminActionTypes.SET_NUMBER_OF_ACTIVE_FILTERS:
      return {
        ...state,
        numOfActiveFilters: action.payload,
      };
    case adminActionTypes.SET_NEW_WITNESS_ID:
      return {
        ...state,
        newWitnessId: action.payload,
      };
    case adminActionTypes.CREATE_WITNESS_DUPLICATE_VALIDATION_FAILURE:
      return {
        ...state,
        duplicateWitnessNames: action.payload,
      };
    case adminActionTypes.CREATE_WITNESS_DUPLICATE_VALIDATION_SUCCESS:
      return {
        ...state,
        duplicateWitnessNames: initialState.duplicateWitnessNames,
      };
    case adminActionTypes.GET_VOLUNTEERS_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        volunteerList: action.payload,
      };
    case adminActionTypes.SET_IS_LOADING:
      return {
        ...state,
        initDataLoading: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
