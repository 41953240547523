import { RootState } from "../reducers";

export const salonsSelector = (state: RootState) => state.salonsReducer;

export const salonsListSelector = (state: RootState) =>
  salonsSelector(state).salonsList;
export const salonsFullListSelector = (state: RootState) =>
  salonsSelector(state).salonsFullList;
export const salonsCountAllPagesSelector = (state: RootState) =>
  salonsSelector(state).salonsCountAllPages;
export const totalSalonsAmountNoFiltersSelector = (state: RootState) =>
  salonsSelector(state).totalSalonsAmountNoFilters;
export const isGetSalonsListLoadingSelector = (state: RootState) =>
  salonsSelector(state).isGetSalonsListLoading;
export const nextFiltersSelector = (state: RootState) =>
  salonsSelector(state).nextFilters;
export const previousFiltersSelector = (state: RootState) =>
  salonsSelector(state).previousFilters;
export const witnessesListForTeamingSelector = (state: RootState) =>
  salonsSelector(state).witnessesListForTeaming;
export const hostSalonsListForTeamingSelector = (state: RootState) =>
  salonsSelector(state).hostSalonsListForTeaming;
export const isGetTeamingDataLoadingSelector = (state: RootState) =>
  salonsSelector(state).isGetTeamingDataLoading;
export const selectedForTeamingSelector = (state: RootState) =>
  salonsSelector(state).selectedForTeaming;
export const relevantOptionsForTeamingListSelector = (state: RootState) =>
  salonsSelector(state).relevantOptionsForTeamingList;
export const teamingTableParmasSelector = (state: RootState) =>
  salonsSelector(state).teamingTableParmas;
export const arePaginationOptionsLoadingSelector = (state: RootState) =>
  salonsSelector(state).arePaginationOptionsLoading;
export const isNextPaginationPageAvailableSelector = (state: RootState) =>
  salonsSelector(state).isNextPaginationPageAvailable;
export const nextTeamingOptionsPaginationPageSelector = (state: RootState) =>
  salonsSelector(state).nextTeamingOptionsPaginationPage;
export const currentSalonDetailsSelector = (state: RootState) =>
  salonsSelector(state).currentSalonDetails;
export const errorSelector = (state: RootState) => salonsSelector(state).error;
export const isIOSModalOpenSelector = (state: RootState) =>
  salonsSelector(state).isIOSModalOpen;
