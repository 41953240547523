export const getWitnessType = (value: string) => {
  switch (value) {
    case "generation_2":
      return "admin.table.secondGeneration";
    case "generation_3":
      return "admin.table.thirdGeneration";
    case "holocaust_survivor":
      return "admin.table.holocaustSurvivor";
    case "expert":
      return "admin.table.expert";
    case "other":
      return "admin.table.other";
    default:
      return value;
  }
};
const Wa = "Wheelchair accessibility";
const Ev = "Elevator";
// const Fl = "floor0";
export const buildSalonAccessiblityString = (elevator, floor, t) => {
  let elvatorParam = t("assignHostWitnessModal.noElevator");

  if (elevator) {
    elvatorParam = elevator.includes(Ev)
      ? t("assignHostWitnessModal.thereIsElevator")
      : elevator.includes(Wa)
      ? t("assignHostWitnessModal.wheelchair")
      : t("assignHostWitnessModal.noElevator");
  }
  const floorStr = t("formFields.floor");
  return `${floorStr} ${floor}, ${elvatorParam}`;
};

export const buildWitnessAccessiblityString = (
  stairs,
  accessibility_need,
  t
) => {
  const accessiblityString: Array<string> = [];
  !stairs && accessiblityString.push(t("admin.salonTeaming.noStairs"));
  accessibility_need &&
    accessiblityString.push(t(`admin.witnessProfile.${accessibility_need}`));

  if (!accessiblityString.length) {
    accessiblityString.push(t("admin.salonTeaming.acceessibilityNotNeeded"));
  }
  return accessiblityString.join(", ");
};

export const buildWitnessAvailabilityHoursString = (availability, t) => {
  const hours = [];
  availability.can_morning && hours.push(t("admin.salonTeaming.morning"));
  availability.can_afternoon && hours.push(t("admin.salonTeaming.afternoon"));
  availability.can_evening && hours.push(t("admin.salonTeaming.evening"));
  return hours.join(", ");
};

export const buildBooleanFieldStr = (value: boolean, t) => {
  if (value) {
    return t("admin.table.yes");
  }
  return t("admin.table.no");
};

export const buildPhoneNumberString = (value: string) => {
  return `0${value.substring(4)}`;
};

export const stringFieldStr = (value: any, t) => {
  switch (value) {
    case "not_contact_this_year":
      return t("admin.table.statusNoContactThisYear");
    case "available_this_year":
      return t("admin.table.statusAvailableThisYear");
    case "not_available_this_year":
      return t("admin.table.statusNotAvailbleThisYear");
    case "did_not_answer":
      return t("admin.table.statusDidNotAnswer");
    case "generation_2":
      return t("admin.table.secondGeneration");
    case "generation_3":
      return t("admin.table.thirdGeneration");
    case "archived":
      return t("admin.table.archived");
    case "holocaust_survivor":
      return t("admin.table.holocaustSurvivor");
    case "expert":
      return t("admin.table.expert");
    case "other":
      return t("admin.table.other");
    case "waiting_for_first_assignment":
      return t("admin.table.waitForTeaming");
    case "waiting_for_more_assignments":
      return t("admin.table.waitForMoreTeaming");
    case "assigned":
      return t("host.assigned");
    case "No Community":
      return t("admin.table.noCommunity");
    case "approved":
      return t("guests.table.approved");
    case "denied":
      return t("guests.table.denied");
    case "pending":
      return t("guests.table.wait");

    default:
      return value;
  }
};
