import styled, { css } from "styled-components/macro";
import { colorDarkGrey, colorRed, colorPink, colorWhite } from "./colors";
import { mediumFont, secondaryLightFont } from "./fonts";
import { mobile } from "./media";

export const h2 = css`
  font-size: 3.4rem;
  line-height: 129.1%;
`;

export const h4 = css`
  font-size: 2.4rem;
  line-height: 130%;
`;

export const h6 = css`
  font-size: 2rem;
  line-height: 105.53%;
  letter-spacing: 0.15px;
`;

export const body16 = css`
  font-size: 1.6rem;
  line-height: 114.11%;
`;

export const subtitle = css`
  font-size: 1.4rem;
  line-height: 106.99%;
  letter-spacing: 0.1px;
`;

export const body14 = css`
  font-size: 1.4rem;
  line-height: 97.26%;
  letter-spacing: 0.25px;
`;

export const body18 = css`
  font-size: 1.8rem;
  line-height: 172%;
  letter-spacing: 0.15px;
`;

export const body12 = css`
  font-size: 1.2rem;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

export const button = css`
  font-size: 1.4rem;
  font-family: ${mediumFont};
  line-height: 2.056rem;
  letter-spacing: 0.5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  padding: 0.4rem 0.6rem;
  flex-grow: 0;
  border: none;
  color: ${colorWhite};
  border-radius: 3px;

  &:disabled {
    opacity: 0.38;
    cursor: auto;
  }
`;

export const buttonOutlined = css`
  border: 1px solid ${colorDarkGrey};
  background: transparent;
  transition: all 0.2s ease-out;

  &:hover {
    background: rgba(238, 71, 153, 0.1);
  }

  &:active {
    background: rgba(238, 71, 153, 0.3);
  }
`;

export const logoHeader = css`
  font-size: 4.3rem;
  line-height: 130%;
  font-family: ${secondaryLightFont};
`;

export const PagePadding = styled.div<{ isPadding?: string }>`
  padding: ${({ isPadding }) =>
    isPadding === "host" ? "0 40px" : "2rem 40px"};

  ${mobile(css`
    padding: 0;
  `)};
`;

const error = css`
  ${body12};
  color: ${colorRed};
`;

export const Error = styled.div<{ error?: string; top?: string }>`
  ${error};
  padding: ${({ error }) => (error ? "0.2rem 0" : "0")};
  margin-top: 10px;
  display: flex;
  height: 10px;
  font-size: 1.4rem;
  align-items: end;
  @media (max-width: 750px) {
    top: 153px;
  }
  ${mobile(css<{ top?: string }>`
    top: ${({ top }) => (top ? top : "188px")};
  `)};
`;

// @ts-ignore
export const FormError = styled(Error)`
  margin-top: -3rem;
`;

// @ts-ignore
export const TopError = styled(Error)`
  margin-bottom: 6px;
  ${body14}
  font-weight: bolder;
`;

export const WitnessProfileError = styled(Error)<{ validationPos?: string }>`
  position: absolute;
  top: 1.85rem;
  right: ${(props) => (props.validationPos ? props.validationPos : "0.4rem")};
`;

export const SuccessTitle = styled.div`
  ${h4};
  text-align: center;
  color: ${colorPink};
`;

export const SuccessSubtitle = styled.div`
  ${body16};
  text-align: center;
  margin-top: 1rem;
`;
