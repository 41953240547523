import pathToRegexp from 'path-to-regexp';
type Params = { [key: string]: number | string | boolean };

export const routes = {
  dashboard: '/admin',
  hosts: {
    root: '/admin/hosts',
    list: '/admin/hosts/list',
    profile: '/admin/hosts/profile/:id',
  },
  witness: {
    root: '/admin/witnesses',
    list: '/admin/witnesses/list',
    profile: '/admin/witnesses/profile/:id',
    addWitness: '/admin/witnesses/addwitness',
  },
  salons: {
    root: '/admin/salons',
  },
  guests: {
    root: '/admin/guests',
    list: '/admin/guests/list',
    profile: '/admin/guests/profile/:id',
  },
  salonTeaming: {
    root: '/admin/salonteaming',
    map: '/admin/salonteaming/mapview',
    table: '/admin/salonteaming/tableview',
  },
  register: {
    root: '/register',
    policyTermsHeb: '/register/host/policyterms/he',
    policyTermsEng: '/register/host/policyterms/en',
    registerHost: '/register/host',
    registerGuest: '/register/guest',
    registerGuestOnboarding: '/register/guest/onboarding',
    registerHostOnboarding: '/register/host/onboarding',
    registerGuestResult: '/registerguestresult',
    createSalon: '/register/createsalon',
    createSalonHost: '/register/createsalon/host',
    createSalonVideo: '/register/createsalon/video',
    createSalonPrivateOrOrg: '/register/createsalon/privateororg',
    createSalonDetails: '/register/createsalon/details',
    createSalonResult: '/createsalonresult',
    wantToTell: '/register/wanttotell',
    witnessRegistration: '/register/witnessregistration',
    witnessRegistrationStepRegister: '/register/witnessregistration/register',
    witnessRegistrationStepDetails: '/register/witnessregistration/details',
    witnessRegistrationStepExtraDetails:
      '/register/witnessregistration/extradetails',
    witnessRegistrationStepBirth: '/register/witnessregistration/birth',
    witnessRegistrationStepAvailability:
      '/register/witnessregistration/availability',
  },
  forgotPassword: {
    restoreMethod: '/forgotpassword/restoremethod',
    phone: '/forgotpassword/phone',
    email: '/forgotpassword/email',
    password: '/forgotpassword/:uuid',
    success: '/forgotpassword/success',
  },
  hostProfile: {
    profile: '/site/host/:id/profile',
    salon: '/site/host/:id/salon',
  },
  errorPage: {
    error: '/site/errorpage',
  },
  guestProfile: {
    profile: '/site/guest/:id/profile',
    salon: '/site/guest/:id/salon',
  },
  salonSearch: {
    root: '/site/salonSearch',
    oneSalonMap: '/site/salonSearch/mapview/:id?',
    mapView: '/site/salonSearch/mapview/',
    salonTable: '/site/salonSearch/table',
  },
  loginWithSms: {
    getCodeViaSms: '/getcodeviasms',
    codeVerification: '/codeverification',
  },
  zikaronBasalon: {
    root: 'https://www.zikaronbasalon.com/',
  },
  accessibilityPage: {
    root: '/accessibility',
  }
};

export const buildPath = (
  route: string,
  params: Params | undefined = undefined
) => {
  const toPath = pathToRegexp.compile(route);
  return toPath(params);
};
