import { RootState } from "../reducers";

export const guestsSelector = (state: RootState) => state.guestsReducer;

export const languagesSelector = (state: RootState) =>
  guestsSelector(state).languages;
export const languageErrorSelector = (state: RootState) =>
  guestsSelector(state).languageError;
export const isAvailableSalonsLoadingSelector = (state: RootState) =>
  guestsSelector(state).isAvailableSalonsLoading;
export const availableSalonsListSelector = (state: RootState) =>
  guestsSelector(state).availableSalonsList;
export const availableSalonsErrorSelector = (state: RootState) =>
  guestsSelector(state).availableSalonsError;
export const guestProfileSelector = (state: RootState) =>
  guestsSelector(state).guestProfile;
export const guestSalonSelector = (state: RootState) =>
  guestsSelector(state).guestSalon;
export const guestIdSelector = (state: RootState) =>
  guestsSelector(state).guestId;
export const errorSelector = (state: RootState) => guestsSelector(state).error;
export const errorKeysSelector = (state: RootState) =>
  guestsSelector(state).errorKeys;
export const guestsListSelector = (state: RootState) =>
  guestsSelector(state).guestsList;
export const guestsFullListSelector = (state: RootState) =>
  guestsSelector(state).guestsFullList;
export const digitalSalonsListSelector = (state: RootState) =>
  guestsSelector(state).digitalSalonsList;
export const digitalSalonsCountAllPagesSelector = (state: RootState) =>
  guestsSelector(state).digitalSalonsCountAllPages;
export const totalDigitalSalonsAmountNoFiltersSelector = (state: RootState) =>
  guestsSelector(state).totalDigitalSalonsAmountNoFilters;
export const isGetDigitalSalonsListLoadingSelector = (state: RootState) =>
  guestsSelector(state).isGetDigitalSalonsListLoading;
export const nextDigitalSalonFiltersSelector = (state: RootState) =>
  guestsSelector(state).nextDigitalSalonFilters;
export const previousDigitalSalonFiltersSelector = (state: RootState) =>
  guestsSelector(state).previousDigitalSalonFilters;
export const guestCountAllPagesSelector = (state: RootState) =>
  guestsSelector(state).guestCountAllPages;
export const totalGuestsAmountNoFiltersSelector = (state: RootState) =>
  guestsSelector(state).totalGuestsAmountNoFilters;
export const nextFiltersSelector = (state: RootState) =>
  guestsSelector(state).nextFilters;
export const previousFiltersSelector = (state: RootState) =>
  guestsSelector(state).previousFilters;
export const isGetGuestsListLoadingSelector = (state: RootState) =>
  guestsSelector(state).isGetGuestsListLoading;
export const guestRegistrationStatusSelector = (state: RootState) =>
  guestsSelector(state).guest_registration_status;
export const guestPersonalDetailsEditSelector = (state: RootState) =>
  guestsSelector(state).guestPersonalDetailsEdit;
export const unassignedGuestSelectedSalon = (state: RootState) =>
  guestsSelector(state).unassignedGuestSalonDetails;
