import { instance as axios } from "../middleware/auth.middleware";

const witnessUrl = "/api/witnesses";
const salonsUrl = "/api/salons";

export const addWitnessCommentCall = (witnessId: string, data: any) => {
  return axios.post(`${witnessUrl}/${witnessId}/witness_comments/`, data);
};

export const getWitnessAvailabeSalonsCall = (witnessId: string) => {
  return axios.get(`${witnessUrl}/${witnessId}/witnesses_availability_salons/`);
};

export const getFutureWitnessSalonsListCall = (witnessId: string) => {
  return axios.get(`/api/salons/witness_future_salons/?witness=${witnessId}`);
};

export const updateSalonslistCall = (witnessId: string, data: any) => {
  return axios.post(
    `${witnessUrl}/${witnessId}/witnesses_availability_salons/bulk_update_witness_salon_list/`,
    data
  );
};

export const getWitnessesListCall = (params) => {
  return axios.get(`${witnessUrl}/`, { params });
};

export const getAllWitnessesListCall = (params) => {
  if (params) return axios.get(`${witnessUrl}/`, { params });
  else return axios.get(`${witnessUrl}/?pagination=False`);
};

export const getWitnessProfileCall = (witnessId: string) => {
  return axios.get(`${witnessUrl}/${witnessId}/`);
};

export const updateWitnessProfileCall = (
  witnessId: string,
  witnessData: any
) => {
  return axios.patch(`${witnessUrl}/${witnessId}/`, witnessData);
};

export const getWitnessSalonsCall = (witnessId: string) => {
  return axios.get(`${witnessUrl}/${witnessId}/salons/`);
};

export const getWitnessDetailsForHostCall = (witnessId: number) => {
  return axios.get(`${witnessUrl}/${witnessId}/details_for_host/`);
};

export const deleteWitnessProfileCall = (witnessId: any) => {
  return axios.delete(`${witnessUrl}/${witnessId}/`);
};

export const updateWitnessDetailsCall = (witnessId: number, data: any) => {
  return axios.patch(`${salonsUrl}/${witnessId}/`, data);
};

export const getWitnessesForTeaming = (params?: { pagination: boolean }) => {
  return axios.get(`${witnessUrl}_teaming/`, { params });
};

export const getWitnessTeamingDetails = (id: number) => {
  return axios.get(`${witnessUrl}_teaming/${id}/`);
};

export const getRelevantHostsForTeaming = (
  witnessId: number,
  cityParams?: any,
  nextPaginationPage?: number
) => {
  return axios.get(
    `${witnessUrl}/${witnessId}/relevant_host_salons/${
      nextPaginationPage
        ? `?page=${nextPaginationPage}&pagination=true`
        : "?pagination=true"
    }`,
    {
      params: cityParams,
    }
  );
};

export const deleteWitnessAssigendSalonCall = (salonId: number) => {
  return axios.patch(`${salonsUrl}/${salonId}/`, { witness_salon: null });
};

export const deleteWitnessTeamCommentCall = (
  witnessId: string,
  commentId: number
) => {
  return axios.delete(
    `${witnessUrl}/${witnessId}/witness_comments/${commentId}/`
  );
};
