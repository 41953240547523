import axios from "axios";
import moment from "moment";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/admin/witnesses.action";
import history from "../../history";
import { witnessActionTypes } from "../actions/admin/witnesses.types";
import {
  addWitnessCommentCall,
  deleteWitnessProfileCall,
  getFutureWitnessSalonsListCall,
  getWitnessAvailabeSalonsCall,
  getWitnessDetailsForHostCall,
  getWitnessProfileCall,
  getWitnessSalonsCall,
  updateSalonslistCall,
  updateWitnessProfileCall,
  getWitnessesListCall,
  deleteWitnessAssigendSalonCall,
  getAllWitnessesListCall,
  deleteWitnessTeamCommentCall,
} from "../apiCalls/witness";
import { routes } from "../../utils/constants/routes";
import { getFiltersStrings } from "../../utils/createTableFiltersRequest";
import sagaSetErrorHelperFn from "../../components/common/ErrorHandler/sagaSetErrorHelperFn";
import { handleErrorFromServer } from "../actions/errors/error.action";

const selectLanguages = (store) => store.adminReducer.languages;

function* getWitnessesList(action) {
  try {
    const { payload } = action;
    if (payload.free_text) yield put({ type: "VALUE_NOT_EMPTY" });
    yield put({ type: witnessActionTypes.GET_WITNESSES_LIST_LOADING });
    const { data } = yield call(() => getWitnessesListCall(payload));

    const witnessesFullNameAndCity = data.results.map((obj) => ({
      ...obj,
      fullName: obj.first_name + " " + obj.last_name,
    }));

    const actionData = {
      witnessesList: witnessesFullNameAndCity,
      witnessCountAllPages: data.count,
      totalWitnessAmountNoFilters: data.total_no_filters,
      ...getFiltersStrings(data.next, data.previous),
    };
    yield put(actions.getWitnessesListSuccess(actionData));
    yield put({ type: "REQUEST_SUCCESS" });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getAllWitnessesList(action) {
  const { payload } = action;
  try {
    const params = { ...payload, pagination: false };

    const { data } = yield call(() => getAllWitnessesListCall(params));
    yield put(actions.getAllWitnessListSuccess(data));
  } catch (e) {
    yield put(actions.getWitnessProfileFailure(e));
  }
}

function* getWitnessProfile(action) {
  try {
    const languagesList = yield select(selectLanguages);

    const { data } = yield call(() => getWitnessProfileCall(action.payload));
    const mobileOrPhoneNumber =
      data.contact_phone.length === 12
        ? data.contact_phone.substr(4, 1)
        : data.contact_phone.substr(4, 2);
    const dataToArray = [data];
    const witnessesCityAndLang = dataToArray.map((witness) => ({
      ...witness,
      address: witness.address && witness.address.replace(",", ""),
      mobile_and_phone_number_pre: "0" + mobileOrPhoneNumber,
      mobile_number_pre: witness.mobile_number
        ? "0" + witness.mobile_number.substr(4, 2)
        : "",
      phone_number_pre:
        (witness.phone_number && witness.phone_number.startsWith("77", 4)) ||
        witness.phone_number.startsWith("79", 4)
          ? "0" + witness.phone_number.substr(4, 2)
          : witness.phone_number
          ? "0" + witness.phone_number.substr(4, 1)
          : "",
      contact_phone: witness.contact_phone
        ? "0" + witness.contact_phone.substring(4)
        : "",
      mobile_number: witness.mobile_number
        ? "0" + witness.mobile_number.substring(4)
        : "",
      phone_number: witness.phone_number
        ? "0" + witness.phone_number.substring(4)
        : "",
      created_at: moment(data.created_at).format("DD/MM/YYYY"),
      witnessLang: dataToArray[0].languages.map((language) => ({
        ...language,
        witnessLang: languagesList.find((res) => res.id === language).name,
      })),
    }));

    const finalData = { ...witnessesCityAndLang }[0];

    yield put(actions.getWitnessProfileSuccess(finalData));
  } catch (e) {
    yield put(actions.getWitnessProfileFailure(e));
  }
}

function* updateWitnessProfile(action) {
  try {
    const languagesList = yield select(selectLanguages);
    const { witnessId, witnessUpdateData } = action.payload;
    // const witnessUpdateData1 = { ...witnessUpdateData, first_name: '', last_name: '' };
    const { data } = yield call(() =>
      updateWitnessProfileCall(witnessId, witnessUpdateData)
    );

    const mobileOrPhoneNumber =
      data.contact_phone.length === 12
        ? data.contact_phone.substr(4, 1)
        : data.contact_phone.substr(4, 2);

    const dataToArray = [data];
    const witnessesCityAndLang = dataToArray.map((witness) => ({
      ...witness,
      mobile_and_phone_number_pre: "0" + mobileOrPhoneNumber,
      contact_phone: witness.contact_phone
        ? "0" + witness.contact_phone.substring(4)
        : "",
      mobile_number: witness.mobile_number
        ? "0" + witness.mobile_number.substring(4)
        : "",
      phone_number: witness.phone_number
        ? "0" + witness.phone_number.substring(4)
        : "",
      mobile_number_pre: witness.mobile_number
        ? "0" + witness.mobile_number.substr(4, 2)
        : "",
      phone_number_pre:
        (witness.phone_number && witness.phone_number.startsWith("77", 4)) ||
        witness.phone_number.startsWith("79", 4)
          ? "0" + witness.phone_number.substr(4, 2)
          : witness.phone_number
          ? "0" + witness.phone_number.substr(4, 1)
          : "",
      witnessLang: dataToArray[0].languages.map((language) => ({
        ...language,
        witnessLang: languagesList.find((lang) => lang.id === language).name,
      })),
    }));
    const finalData = { ...witnessesCityAndLang }[0];
    yield put(actions.updateWitnessProfileSuccess(finalData));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);

    yield put(handleErrorFromServer(errorArray));
    yield put(actions.updateWitnessProfileFailure(errorArray));
  }
}

function* getWitnessSalons(action) {
  try {
    const { data } = yield call(() => getWitnessSalonsCall(action.payload));
    yield put({
      type: witnessActionTypes.GET_WITNESS_SALONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getAssignedFutureSalons(action) {
  try {
    const { data } = yield call(axios, action.request);
    yield put({
      type: witnessActionTypes.GET_ASSIGNED_FUTURE_SALONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}
function* addWitnessYearComment(action) {
  try {
    const { data } = yield call(axios, action.request);
    yield put({
      type: witnessActionTypes.ADD_WITNESS_YEAR_COMMENT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put(actions.getWitnessYearCommentFailure(e));
  }
}

function* getWitnessYearComment(action) {
  try {
    const { data } = yield call(axios, action.request);
    yield put(actions.getWitnessYearCommentSuccess(data));
  } catch (e) {
    yield put(actions.getWitnessYearCommentFailure(e));
  }
}

function* updateWitnessYearComment(action) {
  try {
    const { data } = yield call(axios, action.request);
    yield put(actions.updateWitnessYearCommentSuccess(data));
  } catch (e) {
    yield put(actions.updateWitnessYearCommentFailure(e));
  }
}

function* addWitnessComment(action) {
  try {
    const { witnessId, commentData } = action.payload;
    const { data } = yield call(() =>
      addWitnessCommentCall(witnessId, commentData)
    );
    yield put(actions.addWitnessCommentSuccess(data));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getWitnessComment(action) {
  try {
    const { data } = yield call(axios, action.request);
    yield put(actions.getWitnessCommentSuccess(data));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}
function* getPastSalons(action) {
  try {
    const { data } = yield call(axios, action.request);
    const dataYearObject = {};

    data.forEach((salon) => {
      const year = moment(salon.salon_time).format("YYYY");
      if (dataYearObject[year]) {
        dataYearObject[year].push(salon);
      } else {
        dataYearObject[year] = [salon];
      }
    });

    yield put(actions.getPastSalonsSuccess(dataYearObject));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
    yield put(actions.getPastSalonsFailure(e));
  }
}

function* getFutureSalons(action) {
  const futuresSalons = yield call(() =>
    getFutureWitnessSalonsListCall(action.payload)
  );
  yield put({
    type: witnessActionTypes.GET_WITNESS_FUTURES_SALONS_SUCCESS,
    payload: { futuresSalons: futuresSalons.data },
  });
}

function* getWitnessAvailabilitySalons(action) {
  try {
    const { witnessId } = action.payload;
    const { data } = yield call(() => getWitnessAvailabeSalonsCall(witnessId));
    yield put(actions.getWitnessAvailbalitySalonsSuccess(data));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* updateWitnessAvailabilitySalons(action) {
  try {
    const { witnessId, data } = action.payload;
    const witnessAvailableSalons = yield call(() =>
      updateSalonslistCall(witnessId, data)
    );
    yield put(
      actions.getWitnessAvailbalitySalonsSuccess(witnessAvailableSalons.data)
    );
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);

    yield put(handleErrorFromServer(errorArray));
    yield put(actions.updateWitnessProfileFailure(errorArray));
  }
}

function* getWitnessDetailsForHost(action) {
  try {
    const { data } = yield call(() =>
      getWitnessDetailsForHostCall(action.payload)
    );
    yield put(actions.getWitnessDetailsForHostSuccess(data));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* deleteWitnessProfile(action) {
  try {
    yield call(() => deleteWitnessProfileCall(action.payload));
    history.push(routes.witness.list);
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* deleteWitnessAssigendSalon(action) {
  try {
    yield call(() => deleteWitnessAssigendSalonCall(action.payload));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* deleteWitnessTeamComment(action) {
  const { witnessId, commentId } = action.payload;
  try {
    yield call(() => deleteWitnessTeamCommentCall(witnessId, commentId));
    yield put(actions.deleteWitnessTeamCommentSuccess(commentId));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* witnessesSaga() {
  yield takeLatest(witnessActionTypes.GET_WITNESSES_LIST, getWitnessesList);
  yield takeLatest(witnessActionTypes.GET_WITNESS_PROFILE, getWitnessProfile);
  yield takeLatest(
    witnessActionTypes.UPDATE_WITNESS_PROFILE,
    updateWitnessProfile
  );
  yield takeLatest(witnessActionTypes.GET_WITNESS_SALONS, getWitnessSalons);
  yield takeLatest(
    witnessActionTypes.GET_ASSIGNED_FUTURE_SALONS,
    getAssignedFutureSalons
  );
  yield takeLatest(
    witnessActionTypes.ADD_WITNESS_YEAR_COMMENT,
    addWitnessYearComment
  );
  yield takeLatest(
    witnessActionTypes.GET_WITNESS_YEAR_COMMENT,
    getWitnessYearComment
  );
  yield takeLatest(
    witnessActionTypes.UPDATE_WITNESS_YEAR_COMMENT,
    updateWitnessYearComment
  );
  yield takeLatest(witnessActionTypes.ADD_WITNESS_COMMENT, addWitnessComment);
  yield takeLatest(witnessActionTypes.GET_WITNESS_COMMENT, getWitnessComment);
  yield takeLatest(witnessActionTypes.GET_PAST_SALONS, getPastSalons);
  yield takeLatest(
    witnessActionTypes.GET_WITNESS_FUTURES_SALONS,
    getFutureSalons
  );
  yield takeLatest(
    witnessActionTypes.GET_WITNESS_AVAILBALITY_SALONS,
    getWitnessAvailabilitySalons
  );
  yield takeLatest(
    witnessActionTypes.UPDATE_WITNESS_AVAILBALITY_SALONS,
    updateWitnessAvailabilitySalons
  );
  yield takeLatest(
    witnessActionTypes.GET_WITNESS_DETAILS_FOR_HOST,
    getWitnessDetailsForHost
  );
  yield takeLatest(
    witnessActionTypes.DELETE_WITNESS_PROFILE,
    deleteWitnessProfile
  );
  yield takeLatest(
    witnessActionTypes.DELETE_WITNESS_ASSIGEND_SALON,
    deleteWitnessAssigendSalon
  );
  yield takeLatest(
    witnessActionTypes.GET_ALL_WITNESS_LIST,
    getAllWitnessesList
  );
  yield takeLatest(
    witnessActionTypes.DELETE_WITNESS_TEAM_COMMENT,
    deleteWitnessTeamComment
  );
}

export default witnessesSaga;
