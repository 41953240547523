import styled, { css } from 'styled-components/macro';
import { headerHeight, cornerHeight } from '../../../utils/css/sizes';
import {
  colorYellow,
  colorDarkGrey,
  colorMain,
} from '../../../utils/css/colors';
import { logoHeader } from '../../../utils/css/typography';
import { bodyFont, lightFont } from '../../../utils/css/fonts';
import {
  SectionPaddingRight,
  SectionPaddingLeft,
} from '../../../utils/css/layout';
import { colorWhite } from '../../../utils/css/colors';
import { mobile } from '../../../utils/css/media';

export const Container = styled.header`
  background: ${colorYellow};
  height: calc(${headerHeight}px - ${cornerHeight}px);
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  user-select: none;
`;

export const AppContainer = styled.header<{
  display?: string;
}>`
  ${SectionPaddingRight};
  ${SectionPaddingLeft};
  background: ${colorWhite};
  height: ${headerHeight}px;
  min-height: 90px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  align-items: center;
  user-select: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.14);
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ActionItem = styled.div<{
  clickable?: boolean;
}>`
  text-decoration-line: ${({ clickable }) => clickable && 'underline'};
  text-underline-offset: 4px;
  cursor: pointer;
  margin-inline-start: 0rem;
  margin-inline-end: 3rem;
  font-size: 20px;
  font-family: ${bodyFont};
`;

export const ActionItemLogout = styled(ActionItem as any)`
  font-size: 18px;
`;

// export const ActionItem = styled.div<{
//   clickable?: boolean;
//   marginLeft?: number;
// }>`
//   text-decoration-line: ${({ clickable }) => clickable && "underline"};
//   cursor: pointer;
//   margin-inline-start: 4rem;
//   margin-left: ${({ marginLeft }) => marginLeft}rem;
//   font-size: 1.6rem;
// `;

export const LogoContainer = styled.a`
  ${logoHeader};
  color: ${colorMain};
  -webkit-text-stroke: 0.4px black;
  display: flex;
`;

export const TextItem = styled.span<{ isSelected: boolean }>`
  margin: 0 8px;
  font-family: ${({ isSelected }) => (isSelected && bodyFont) || lightFont};
`;

export const StyledAnchor = styled.a`
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  margin: 0 0.5rem;
`;

export const ZikaronLogo = styled.img`
height: 72px;
width: 72px;
  ${mobile(css`
    width: 48px;
  `)}
`;

export const BaseItem = styled.div`
  margin-inline-start: 37px;
  font-size: 22px;
  display: flex;
  align-items: center;
  color: ${colorDarkGrey};
  font-family: ${bodyFont};
`;

export const NavBarOptions = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: 58px;
`;

export const Underline = styled.div`
  position: relative;
  border-bottom: 3px solid #ffcb05;
  border-radius: 3px;
`;

export const NavBarActionItemContainer = styled(ActionItem as 'div')`
  ${mobile(css`
    margin-left: 0;
  `)}
`;

export const NavBarActionItemMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #e1e1e1;
  padding: 22px 20px;
`;

export const SelectedActionItemDot = styled.div`
  background: #ffcb05;
  width: 6px;
  height: 6px;
  border: 1px solid #ffcb05;
  border-radius: 50%;
`;
