import styled, { css } from "styled-components/macro";
import { colorBlack } from "../../../utils/css/colors";
import ButtonSmall from "../../common/ButtonSmall";
import { Link } from "react-router-dom";
import { mobile } from "../../../utils/css/media";
import { cardStyle } from "../CommonStyles";

export const Card = styled.div<{ marginTop?: string }>`
  ${cardStyle};
  padding: 6rem 11rem;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "5%")};
  z-index: 100;
  min-width: 23vw;

  ${mobile(css`
    min-width: 100%;
    padding: 7.5rem 5rem;
  `)};
`;

export const CardPannel = styled(Card as any)`
  margin: 0px;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RegisterButton = styled(ButtonSmall)`
  padding: 1rem 6rem;
  margin: 1rem 0;
  width: 100%;
  height: 8rem;
  font-size: 1.6rem;
`;

export const GoToLoginText = styled.p`
  margin-top: 6%;
  font-size: 16px;

  ${mobile(css`
    margin-top: 25%;
    font-size: 1.4rem;
  `)};
`;

export const GoToLoginLink = styled(Link)`
  color: ${colorBlack};
  text-decoration: underline;
`;

export const FormContainer = styled.form.attrs(() => ({
  noValidate: true,
  autoComplete: "off",
}))<{
  width?: string;
  height?: string;
  margin?: string;
}>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => height && height};
  position: relative;
  margin: ${({ margin }) => margin && margin};

`;

export const OnBoardingLayout= styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  ${mobile(css`
    margin: initial;
  `)}
`;

export const OnBoardingHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 100px;

  ${mobile(css`
    margin: 29px 39px 0 39px;
    margin-bottom: 10px;
  `)}
`;

export const OnBoardingTitle = styled.div`
margin-bottom: 10px;  
  font-size: 22px;
`;

export const OnBoardingFieldsContainer = styled.div`
max-width: 400px;
 ${mobile(css`
    width: 100%;§
  `)}
`;

export const OnBoardingSaveButtonContainer = styled.div`
 display: flex;
 justify-content: flex-end;
 flex: 1;
`;

export const OnBoardingSaveButton = styled(ButtonSmall)`
align-self: flex-end;
margin: 0 50px;
 ${mobile(css`
    width: 100%;
    margin: 0;
    justify-self: flex-end;
  `)}
`;