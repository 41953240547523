import { takeLatest, put, call, all, select } from "redux-saga/effects";
import * as actions from "../actions/admin/salons.action";
import { salonsActionTypes } from "../actions/admin/salons.types";
import { getFiltersStrings } from "../../utils/createTableFiltersRequest";
import {
  getWitnessesForTeaming,
  getWitnessTeamingDetails,
  getRelevantHostsForTeaming,
} from "../apiCalls/witness";
import {
  getSalonsCall,
  getHostSalonsListForTeaming,
  getHostSalonTeamingDetails,
  getRelevantWitnessesForTeaming,
  getAllSalonsListCall,
  deleteHostSalonByIdCall,
} from "../apiCalls/hosts";
import { getLanguagesNames } from ".";
import history from "../../history";
import { routes } from "../../utils/constants/routes";
import {
  assignedNewSalon,
  assignedNewSalonNewWitness,
} from "../apiCalls/admin";
import { buildPhoneNumberString } from "../../components/common/getString";
import sagaSetErrorHelperFn from "../../components/common/ErrorHandler/sagaSetErrorHelperFn";
import { handleErrorFromServer } from "../actions/errors/error.action";
import Axios from "axios";
import { sortBy, prop } from "ramda";

const selectLanguages = (store) => store.adminReducer.languages;

function* getSalons(action) {
  try {
    const { payload } = action;
    if (payload.free_text) yield put({ type: "VALUE_NOT_EMPTY" });
    yield put({ type: salonsActionTypes.GET_SALONS_LIST_LOADING });

    const { data } = yield call(() => getSalonsCall(payload));
    const salonsList = data.results,
      salonsCountAllPages = data.count,
      totalSalonsAmountNoFilters = data.total_no_filters;
    yield put(
      actions.getSalonsSuccess({
        salonsList,
        salonsCountAllPages,
        totalSalonsAmountNoFilters,
        ...getFiltersStrings(data.next, data.previous),
      })
    );
    yield put({ type: "REQUEST_SUCCESS" });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getSalonsAndWitnessesForTeaming(action) {
  yield put({ type: salonsActionTypes.GET_TEAMING_DATA_LOADING });
  try {
    const {
      payload: { params },
    } = action;

    if (params.free_text) yield put({ type: "VALUE_NOT_EMPTY" });

    const witnesses = yield call(() => getWitnessesForTeaming(params));
    const hostSalons = yield call(() => getHostSalonsListForTeaming(params));

    let witnessesListForTeaming = witnesses
        ? witnesses.data.map((witness) => ({
            ...witness,
            address: witness.address.replace(",", ""),
          }))
        : {},
      hostSalonsListForTeaming = hostSalons ? hostSalons.data : {};
    yield put({
      type: salonsActionTypes.GET_SALONS_AND_WITNESSES_FOR_TEAMING_SUCCESS,
      payload: {
        witnessesListForTeaming,
        hostSalonsListForTeaming,
      },
    });
    yield put({ type: "REQUEST_SUCCESS" });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getSalonsOrWitnessesForTeaming(action) {
  try {
    const {
      payload: { params, teamingSide },
    } = action;
    if (params.free_text) yield put({ type: "VALUE_NOT_EMPTY" });
    let dataForTeaming;
    if (teamingSide === "witness") {
      dataForTeaming = yield call(() => getWitnessesForTeaming(params));
    } else {
      dataForTeaming = yield call(() => getHostSalonsListForTeaming(params));
    }
    dataForTeaming = {
      ...dataForTeaming.data,
      ...getFiltersStrings(
        dataForTeaming.data.next,
        dataForTeaming.data.previous
      ),
    };
    yield put({
      type: salonsActionTypes.GET_SALONS_OR_WITNESSES_FOR_TEAMING_SUCCESS,
      payload: { dataForTeaming, teamingSide },
    });
    yield put({ type: "REQUEST_SUCCESS" });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getDataForWitnessTeaming(action) {
  try {
    const { payload } = action;
    let { id, cityParams } = payload;

    const languagesList = yield select(selectLanguages);

    yield put({ type: salonsActionTypes.GET_TEAMING_DATA_LOADING });
    const teamingOptions = yield all([
      call(() => getWitnessTeamingDetails(id)),
      call(() => getRelevantHostsForTeaming(id, cityParams)),
    ]);
    const witnessData = teamingOptions[0].data;
    const isNextPaginationPageAvailable = teamingOptions[1].data.next !== null;

    const witnessLanguagesNames = witnessData.languages.map((language) =>
      getLanguagesNames(languagesList, language)
    );
    const selectedForTeaming = {
      ...witnessData,
      address: witnessData.address.replace(",", ""),
      languages: witnessLanguagesNames,
      contact_phone: witnessData.phone
        ? buildPhoneNumberString(witnessData.phone)
        : "",
    };

    const relevantOptions = teamingOptions[1].data.results.map((host) => ({
      ...host,
      address: host.address.replace(",", ""),
      language: getLanguagesNames(languagesList, host.language),
      contact_phone: host.contact_phone
        ? buildPhoneNumberString(host.contact_phone)
        : "",
    }));
    const relevantOptionsForTeamingList = sortBy(prop("distance"))(
      relevantOptions
    );

    yield put({
      type: salonsActionTypes.GET_DATA_FOR_TEAMING_SUCCESS,
      payload: {
        selectedForTeaming,
        relevantOptionsForTeamingList,
        isNextPaginationPageAvailable,
      },
    });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getDataForHostSalonTeaming(action) {
  try {
    const { payload } = action;
    let { id, cityParams } = payload;

    const languagesList = yield select(selectLanguages);

    yield put({ type: salonsActionTypes.GET_TEAMING_DATA_LOADING });
    const teamingOptions = yield all([
      call(() => getHostSalonTeamingDetails(id)),
      call(() => getRelevantWitnessesForTeaming(id, cityParams)),
    ]);

    const hostSalonData = teamingOptions[0].data;
    const isNextPaginationPageAvailable = teamingOptions[1].data.next !== null;

    const selectedForTeaming = {
      ...hostSalonData,
      address: hostSalonData.address.replace(",", ""),
      language: getLanguagesNames(languagesList, hostSalonData.language),
      contact_phone: hostSalonData.contact_phone
        ? buildPhoneNumberString(hostSalonData.contact_phone)
        : "",
    };

    const relevantOptionsForTeamingList = teamingOptions[1].data.results.map(
      (witness) => ({
        ...witness,
        address: witness.address.replace(",", ""),
        languages: witness.languages.map((language) =>
          getLanguagesNames(languagesList, language)
        ),
        contact_phone: witness.phone
          ? buildPhoneNumberString(witness.phone)
          : "",
      })
    );

    yield put({
      type: salonsActionTypes.GET_DATA_FOR_TEAMING_SUCCESS,
      payload: {
        selectedForTeaming,
        relevantOptionsForTeamingList,
        isNextPaginationPageAvailable,
      },
    });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getMoreHostSalonsPaginationTeamingOptions(action) {
  try {
    const { payload } = action;
    let { id, cityParams, currentPaginationPage, displayInfomationType } =
      payload;

    const languagesList = yield select(selectLanguages);

    yield put({
      type: salonsActionTypes.GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_INIT,
    });

    let teamingOptions;
    displayInfomationType === "hostSalon" || displayInfomationType === "salons"
      ? (teamingOptions = yield all([
          call(() => getHostSalonTeamingDetails(id)),
          call(() =>
            getRelevantWitnessesForTeaming(
              id,
              cityParams,
              currentPaginationPage
            )
          ),
        ]))
      : (teamingOptions = yield all([
          call(() => getWitnessTeamingDetails(id)),
          call(() =>
            getRelevantHostsForTeaming(id, cityParams, currentPaginationPage)
          ),
        ]));

    const nextPaginationPage =
      teamingOptions[1].data.next !== null ? ++currentPaginationPage : null;

    let relevantOptionsForTeamingList;
    displayInfomationType === "hostSalon" || displayInfomationType === "salons"
      ? (relevantOptionsForTeamingList = teamingOptions[1].data.results.map(
          (witness) => ({
            ...witness,
            address: witness.address.replace(",", ""),
            languages: witness.languages.map((language) =>
              getLanguagesNames(languagesList, language)
            ),
            contact_phone: buildPhoneNumberString(witness.phone),
          })
        ))
      : (relevantOptionsForTeamingList = teamingOptions[1].data.results.map(
          (host) => ({
            ...host,
            address: host.address.replace(",", ""),
            language: getLanguagesNames(languagesList, host.language),
            contact_phone: buildPhoneNumberString(host.contact_phone),
          })
        ));

    yield put({
      type: salonsActionTypes.GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_SUCCESS,
      payload: { relevantOptionsForTeamingList, nextPaginationPage },
    });
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* assignNewSalon(action) {
  try {
    const { payload } = action;
    yield call(() => assignedNewSalon(payload.hostId, payload.witnessId));
    payload.callback(true);
  } catch (e) {
    action.payload.callback(false);
  }
}

function* assignNewSalonNewWitness(action) {
  try {
    const { payload } = action;
    yield call(() =>
      assignedNewSalonNewWitness(payload.salonId, payload.witnessSalonId)
    );
    payload.callback(true);
  } catch (e) {
    action.payload.callback(false);
  }
}

function* getAllSalonList(action) {
  const { payload } = action;
  try {
    const params = { ...payload, pagination: false };
    const { data } = yield call(() => getAllSalonsListCall(params));
    yield put(actions.getAllSalonListSuccess(data));
  } catch (e) {
    action.payload.callback(false);
  }
}

function* getHostSalonBySalonId(action) {
  try {
    const { data } = yield call(Axios, action.request);
    yield put(actions.getHostSalonBySalonIdSuccess(data));
  } catch (error) {
    yield put(actions.getHostSalonBySalonIdFailure(error.message));
  }
}

function* adminDeleteHostSalonById(action) {
  try {
    yield call(() => deleteHostSalonByIdCall(action.payload));
    history.push(routes.salons.root);
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* salonsSaga() {
  yield takeLatest(salonsActionTypes.GET_SALONS, getSalons);
  yield takeLatest(
    salonsActionTypes.GET_SALONS_AND_WITNESSES_FOR_TEAMING,
    getSalonsAndWitnessesForTeaming
  );
  yield takeLatest(
    salonsActionTypes.GET_SALONS_OR_WITNESSES_FOR_TEAMING,
    getSalonsOrWitnessesForTeaming
  );
  yield takeLatest(
    salonsActionTypes.GET_DATA_FOR_WITNESS_TEAMING,
    getDataForWitnessTeaming
  );
  yield takeLatest(
    salonsActionTypes.GET_DATA_FOR_HOST_SALON_TEAMING,
    getDataForHostSalonTeaming
  );
  yield takeLatest(salonsActionTypes.ASSIGNE_NEW_SALON, assignNewSalon);
  yield takeLatest(
    salonsActionTypes.ASSIGNE_NEW_SALON_NEW_WITNESS,
    assignNewSalonNewWitness
  );
  yield takeLatest(salonsActionTypes.GET_ALL_SALONS_LIST, getAllSalonList);
  yield takeLatest(
    salonsActionTypes.GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS,
    getMoreHostSalonsPaginationTeamingOptions
  );
  yield takeLatest(
    salonsActionTypes.GET_HOST_SALON_BY_SALON_ID_REQUEST,
    getHostSalonBySalonId
  );
  yield takeLatest(
    salonsActionTypes.ADMIN_DELETE_HOST_SALON_BY_ID,
    adminDeleteHostSalonById
  );
}

export default salonsSaga;
