
import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
// components
import Header from './Header';
// utils
import { Container, MobilePinkBg, PageContainer } from './CommonStyles';
import { heTheme, enTheme } from '../../utils/MuiTheme';
import { MobileContactFooter, MobileContactLink } from './Login/loginStyle';
import { mobileWidth } from '../../utils/css/sizes';
import useWindowSize from '../common/useWindowSize';
import loginBGMobile from '../../assets/icons/loginBGMobile.svg';
import getDirection from '../../utils/getLanguageDirection';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Layout = ({
  showHomePageSection = true,
  showBackToHomePage = true,
  showHeader = true,
  backgroundColor = null,
  children,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const langDirection = getDirection();
  const [width] = useWindowSize();


  const pageBody = (
    <>
      {showHeader && (
        <Header
          showHomePageSection={showHomePageSection}
          showBackToHomePage={showBackToHomePage}
        />
      )}
      {children}
    </>
  );


  return (
    <ThemeProvider theme={language === 'en' ? enTheme : heTheme}>
      <StylesProvider jss={jss}>
        <Container
          backgroundColor={backgroundColor}
          lng={language}
          setJustify={width <= mobileWidth ? 'space-between' : ''}
        >
          {width > mobileWidth ? (
            <PageContainer>{pageBody}</PageContainer>
          ) : (
            pageBody
          )}
        </Container>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default Layout;
