import i18n from "../i18n";

const getDirection = () => {
  let lang: "rtl" | "ltr";
  if (i18n.language === "he") {
    lang = "rtl";
  } else lang = "ltr";
  return lang;
};

export default getDirection;
