import React from "react";
import {
  FieldContainer,
  FieldTitle,
  FlexDiv,
  FlexColumnField,
} from "./FormFieldContainerStyle";
import { useTranslation } from "react-i18next";
import { Error } from "../../../utils/css/typography";
import CustomizedTooltip from "../Tooltip";
import exclamationMark from "../../../assets/icons/exclamationMark.svg";

interface IFormFieldContainer {
  title?: string;
  isFlexBasis?: string;
  hasExposedNote?: boolean;
  fullWidthField?: boolean;
  required?: boolean;
  children: React.ReactElement | JSX.Element[];
  widthOfField?: string;
  heightDiv?: string;
  isExposeToGuest?: boolean;
  widthOfDiv?: string;
  paddingOfDiv?: string;
  flexOfTitle?: string;
  error?: string;
  minWidth?: number;
  className?: string;
  isToolTip?: boolean;
  toolTipTitle?: string;
  alignItems?: string;
  hideColon?: boolean;
  isBold?: boolean;
  alignCenter?: boolean;
  isColumn?: boolean;
  isWordBreak?: boolean;
  flexColumnFieldPosition?: string;
  colorUnderSpanPosition?: string;
  colorUnderSpanTop?: string;
  flexDir?: string;
  fieldTitlePosition?: string;
  fieldTitleTop?: string;
  fieldTitleRight?: string;
  display?: string;
  marginTop? :string;
  gapBetween?: string;
}

const FormFieldContainer: React.FC<IFormFieldContainer> = ({
  children,
  title,
  hasExposedNote,
  fullWidthField,
  isFlexBasis,
  widthOfField,
  heightDiv,
  isExposeToGuest,
  widthOfDiv,
  paddingOfDiv,
  flexOfTitle,
  error,
  minWidth,
  className,
  isToolTip,
  toolTipTitle,
  alignItems,
  alignCenter,
  required,
  hideColon,
  isBold = false,
  isColumn,
  isWordBreak,
  flexColumnFieldPosition,
  colorUnderSpanPosition,
  colorUnderSpanTop,
  flexDir,
  display,
  marginTop,
  gapBetween,
}) => {
  const { t } = useTranslation();
  return (
    <FieldContainer
      padding={paddingOfDiv}
      fullWidthField={fullWidthField}
      isFlexBasis={isFlexBasis}
      widthOfField={widthOfField}
      minWidth={minWidth}
      className={className}
      isWordBreak={isWordBreak}
      height={heightDiv}
    >
      <FlexColumnField
        widthOfDiv={widthOfDiv}
        flexColumnFieldPosition={flexColumnFieldPosition}
        display={display}
        color={"red"}
        marginTop={marginTop}
      >
        <FlexDiv
          className="flex-div"
          isColumn={isColumn}
          widthOfDiv={widthOfDiv}
          alignItems={alignItems}
          heightDiv={heightDiv}
          flexDir={flexDir}
          gapBetween={gapBetween}
        >
          {title && (
            <FieldTitle
              alignCenter={alignCenter}
              flex={flexOfTitle}
              isBold={isBold}
              className={className}
            >
              {`${t(title)}${hideColon ? "" : " *"}`}
            </FieldTitle>
          )}
          {isToolTip ? (
            <CustomizedTooltip
              isPosition="relative"
              title={toolTipTitle}
              element={<img src={exclamationMark} alt="info" />}
            />
          ) : null}
          {children}
        </FlexDiv>
        {error && <Error>{t(error)}</Error>}
      </FlexColumnField>
    </FieldContainer>
  );
};

export default FormFieldContainer;
