export enum salonsActionTypes {
  GET_SALONS = "GET_SALONS",
  GET_SALONS_LIST_LOADING = "GET_SALONS_LIST_LOADING",

  GET_SALONS_SUCCESS = "GET_SALONS_SUCCESS",
  GET_SALONS_FAILURE = "GET_SALONS_FAILURE",

  ADMIN_DELETE_HOST_SALON_BY_ID = "ADMIN_DELETE_HOST_SALON_BY_ID",

  GET_SALONS_AND_WITNESSES_FOR_TEAMING = "GET_SALONS_AND_WITNESSES_FOR_TEAMING",
  GET_SALONS_AND_WITNESSES_FOR_TEAMING_SUCCESS = "GET_SALONS_AND_WITNESSES_FOR_TEAMING_SUCCESS",

  GET_SALONS_OR_WITNESSES_FOR_TEAMING = "GET_SALONS_OR_WITNESSES_FOR_TEAMING",
  GET_SALONS_OR_WITNESSES_FOR_TEAMING_SUCCESS = "GET_SALONS_OR_WITNESSES_FOR_TEAMING_SUCCESS",

  GET_DATA_FOR_WITNESS_TEAMING = "GET_DATA_FOR_WITNESS_TEAMING",
  GET_DATA_FOR_TEAMING_SUCCESS = "GET_DATA_FOR_TEAMING_SUCCESS",

  GET_TEAMING_DATA_LOADING = "GET_TEAMING_DATA_LOADING",

  SET_SELECTED_SALON = "SET_SELECTED_SALON",

  INIT_DATA_FOR_TEAMING = "INIT_DATA_FOR_TEAMING",
  INIT_DATA_FOR_WITNESS_TEAMING = "INIT_DATA_FOR_WITNESS_TEAMING",

  GET_SALONS_AND_WITNESSES_FOR_TEAMING_WITH_PAGINATION_SUCCSSES = "GET_SALONS_AND_WITNESSES_FOR_TEAMING_WITH_PAGINATION_SUCCSSES",

  GET_DATA_FOR_HOST_SALON_TEAMING = "GET_DATA_FOR_HOST_SALON_TEAMING",
  GET_DATA_FOR_HOST_SALON_TEAMING_SUCCSSES = "GET_DATA_FOR_HOST_SALON_TEAMING_SUCCSSES",

  GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_INIT = "GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_INIT",
  GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS = "GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS",
  GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_SUCCESS = "GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_SUCCESS",

  CLEAR_SALONS_AND_WITNESSES_FOR_TEAMING = "CLEAR_SALONS_AND_WITNESSES_FOR_TEAMING",
  ASSIGNE_NEW_SALON = "ASSIGNE_NEW_SALON",
  ASSIGNE_NEW_SALON_NEW_WITNESS = "ASSIGNE_NEW_SALON_NEW_WITNESS",

  GET_ALL_SALONS_LIST = "GET_ALL_SALONS_LIST",
  GET_ALL_SALONS_LIST_SUCCSSES = "GET_ALL_SALONS_LIST_SUCCSSES",

  GET_HOST_SALON_BY_SALON_ID_REQUEST = "GET_HOST_SALON_BY_SALON_ID_REQUEST",
  GET_HOST_SALON_BY_SALON_ID_SUCCESS = "GET_HOST_SALON_BY_SALON_ID_SUCCESS",
  GET_HOST_SALON_BY_SALON_ID_FAILURE = "GET_HOST_SALON_BY_SALON_ID_FAILURE",

  IS_SALON_MODAL_OPEN = "IS_SALON_MODAL_OPEN",
}
