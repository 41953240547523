import React from "react";

const useDetectClickOutside = () => {
  const wrapperRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleIsVisible = () => setIsVisible(!isVisible);

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  return { isVisible, toggleIsVisible, wrapperRef };
};

export default useDetectClickOutside;
