import React from "react";
import useLanguage from "../../common/useLanguage";

import { LanguageChoice, LanguageChoices, Divider } from "./styles";

const LanguageToggle = () => {
  const [selectedLanguage, onLanguageChange] = useLanguage();

  return (
      <LanguageChoices>
            <LanguageChoice isBold={selectedLanguage === "he"} onClick={() => onLanguageChange("he")}>עב</LanguageChoice>
            <Divider/>
            <LanguageChoice isBold={selectedLanguage === "en"} onClick={() => onLanguageChange("en")}>ENG </LanguageChoice>
      </LanguageChoices>
  );
};

export default LanguageToggle;