import styled from 'styled-components/macro';
import { body16, h6 } from '../../../../../utils/css/typography';

export const FieldLable = styled.p`
	${body16}
	margin-bottom: 14px;
`;

export const FlexDivUserDetails = styled.div`
	display: flex;
	align-items: baseline;
	width: 100%;
`;

export const SideBarPadding = styled.div`
	/* padding: 0px 9px; */
`;

// @ts-ignore
export const SelectedUserDetailsContanier = styled(SideBarPadding)<{ isCardClose? }>`
	max-height: ${({ isCardClose }) => (isCardClose ? 0 : '750px')};
	opacity: ${({ isCardClose }) => (isCardClose ? 0 : 1)};
	transition: all 0.5s ease;
	padding: 7px 4px;
`;

export const FlexDivColumnUserDetails = styled.div<{ flexBasis?: string; isBold?: boolean }>`
	display: flex;
	flex-direction: column;
	font-weight: ${props => (props.isBold ? 'bold' : '')};
	padding-bottom: ${props => (props.isBold ? '3px' : '')};
	flex-basis: ${props => (props.flexBasis ? props.flexBasis : 'unset')};
	word-break: break-word;
	${body16};
`;

export const ButtonCenterContainer = styled.div`
	text-align: center;
	margin: 1rem auto;
`;

export const PMargin = styled.p`
	margin-inline-start: 3px;
	display: inline;
`;

export const AvailbiltyOverFlowDiv = styled.div<{ isShowAvailibilty: boolean }>`
	max-height: ${props => (props.isShowAvailibilty ? '70px' : 'unset')};
	overflow: hidden;
`;

export const ArrowImg = styled.img<{ isShowAvailibilty: boolean }>`
	margin-inline-start: 10px;
	transform: ${props => (props.isShowAvailibilty ? 'rotate(0deg)' : 'rotate(180deg)')};
	transition: 0.3s;
`;

export const CursorDiv = styled.div`
	cursor: pointer;
	display: flex;
	align-self: baseline;
`;

export const IconContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 4rem;
`;

export const AssignedText = styled.div`
	font-weight: bold;
	margin: 1rem auto;
	margin-bottom: 63px;
	${h6}
`;
