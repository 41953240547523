import { salonsActionTypes } from "./salons.types";
import { EdisplayInfomationType } from "../../../interfaces/admin";
import { ISalon } from "../../../interfaces/hosts";

export const getSalons = (params) => {
  return {
    type: salonsActionTypes.GET_SALONS,
    payload: params,
  };
};

export const getSalonsSuccess = (data) => {
  return {
    type: salonsActionTypes.GET_SALONS_SUCCESS,
    payload: data,
  };
};
export const adminDeleteHostSalonById = (hostSalonId) => ({
  type: salonsActionTypes.ADMIN_DELETE_HOST_SALON_BY_ID,
  payload: hostSalonId,
});

export const getSalonsFailure = (error: string) => ({
  type: salonsActionTypes.GET_SALONS_FAILURE,
  payload: error,
});

export const getAllSalonList = (params) => {
  return {
    type: salonsActionTypes.GET_ALL_SALONS_LIST,
    payload: params,
  };
};

export const getAllSalonListSuccess = (data) => {
  return {
    type: salonsActionTypes.GET_ALL_SALONS_LIST_SUCCSSES,
    payload: data,
  };
};

export const getSalonsAndWitnessesForTeaming = (params) => ({
  type: salonsActionTypes.GET_SALONS_AND_WITNESSES_FOR_TEAMING,
  payload: { params },
});

export const getSalonsOrWitnessesForTeaming = (
  params,
  teamingSide: EdisplayInfomationType
) => ({
  type: salonsActionTypes.GET_SALONS_OR_WITNESSES_FOR_TEAMING,
  payload: {
    params,
    teamingSide,
  },
});

export const getDataForWitnessTeaming = (
  id: number,
  cityParams: { city: string[] }
) => ({
  type: salonsActionTypes.GET_DATA_FOR_WITNESS_TEAMING,
  payload: { id, cityParams },
});

export const getDataForHostSalonTeaming = (
  id: number,
  cityParams: { city: string[] }
) => ({
  type: salonsActionTypes.GET_DATA_FOR_HOST_SALON_TEAMING,
  payload: { id, cityParams },
});

export const getMoreHostSalonsPaginationTeamingOptions = (
  id: number,
  cityParams: { city: string[] },
  currentPaginationPage: number,
  displayInfomationType: string
) => ({
  type: salonsActionTypes.GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS,
  payload: { id, cityParams, currentPaginationPage, displayInfomationType },
});

export const initDataForTeaming = () => ({
  type: salonsActionTypes.INIT_DATA_FOR_TEAMING,
});

export const setSelectedSalonId = (id: string | undefined) => ({
  type: salonsActionTypes.SET_SELECTED_SALON,
  payload: id
});

export const clearSalonsAndWitnessesForTeaming = () => ({
  type: salonsActionTypes.CLEAR_SALONS_AND_WITNESSES_FOR_TEAMING,
});

export const assignNewSalon = (
  hostId: number,
  witnessId: number,
  callback: (isError: boolean) => void
) => ({
  type: salonsActionTypes.ASSIGNE_NEW_SALON,
  payload: { hostId, witnessId, callback },
});

export const assignNewSalonNewWitness = (
  salonId: number,
  witnessSalonId: number,
  callback: (isError: boolean) => void
) => ({
  type: salonsActionTypes.ASSIGNE_NEW_SALON_NEW_WITNESS,
  payload: { salonId, witnessSalonId, callback },
});

//here
export const getHostSalonBySalonIdRequest = (salonID: number | string) => ({
  type: salonsActionTypes.GET_HOST_SALON_BY_SALON_ID_REQUEST,
  request: {
    url: `/api/host_salons/${salonID}`,
    method: `GET`,
    payload: salonID,
  },
});

export const getHostSalonBySalonIdSuccess = (salon: ISalon) => ({
  type: salonsActionTypes.GET_HOST_SALON_BY_SALON_ID_SUCCESS,
  payload: salon,
});

export const getHostSalonBySalonIdFailure = (error: string) => ({
  type: salonsActionTypes.GET_HOST_SALON_BY_SALON_ID_FAILURE,
  payload: error,
});
export const getisIOSModalOpen = (isIOSModalOpen: boolean) => ({
  type: salonsActionTypes.IS_SALON_MODAL_OPEN,
  payload: isIOSModalOpen,
});
