import React from "react";
import LoaderIcon from "../../assets/icons/LoaderIcon.gif";
import styled from "styled-components/macro";

interface ILoaderProps {
  maxHeight?: number;
}

const Loader: React.FC<ILoaderProps> = ({ maxHeight }) => {
  return (
    <LoaderIconImg>
      <img
        src={LoaderIcon}
        alt="loading"
        style={{ maxHeight: `${maxHeight}rem` }}
      />
    </LoaderIconImg>
  );
};

const LoaderIconImg = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Loader;
