import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import * as authSelectors from "../store/selectors/authSelectors";
import { RouteComponentProps } from "react-router-dom";
import useLogout from "./common/useLogout";
import { UserTypes } from "../interfaces/auth";

interface IProps extends RouteComponentProps {}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const logout = useLogout({ navigateUser: false });

  const token = useSelector(authSelectors.tokenSelector);
  const role = useSelector(authSelectors.roleSelector);
  const isFirstTimeAdminUser = useSelector(
    authSelectors.isFirstTimeAdminUserSelector
  );

  if (
    token &&
    !isFirstTimeAdminUser &&
    role !== UserTypes.HOST &&
    role !== UserTypes.GUEST &&
    role !== UserTypes.WITNESS
  ) {
    return (
      <Route {...rest} render={(props: IProps) => <Component {...props} />} />
    );
  }

  logout();
  return (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};

export default PrivateRoute;
