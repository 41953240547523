import { takeLatest, put, call, select } from "redux-saga/effects";
import { guestActionTypes } from "../actions/guest/guest.types";
import { salonSearchMapActionTypes } from "../actions/guest/salonSearchMap.types";
import {
  redirectToErrorPage,
  handleErrorFromServer,
} from "../actions/errors/error.action";
import {
  getLanguagesSuccess,
  getLanguagesFailure,
  getAvailableSalonsListSuccess,
  getAvailableSalonsListFail,
  getDigitalSalonsSuccess,
} from "../actions/guest/salonSearchMap.action";
import {
  getLanguagesList,
  getAvailableSalonsList,
  getGuestProfileCall,
  updateGuestProfileCall,
  getGuestSalonCall,
  deleteGuestSalonCall,
  updateGuestSalonCall,
  createGuestSalonCall,
  getAllGuestsCall,
  getAllGuestListCall,
} from "../apiCalls/guests";
import * as actions from "../actions/guest/guest.action";
import { getFiltersStrings } from "../../utils/createTableFiltersRequest";
import {
  getGuestsListSucsses,
  getAllGuestsListSuccess,
} from "../actions/admin/guest.action";
import sagaSetErrorHelperFn from "../../components/common/ErrorHandler/sagaSetErrorHelperFn";
import { registerGuestSuccess } from "../actions/auth/auth.action";

import history from "../../history";
import { routes, buildPath } from "../../utils/constants/routes";

const selectLanguages = (store) => store.guestsReducer.languages;

function* getGuestLanguages() {
  let languages = yield select(selectLanguages);
  try {
    if (languages.length === 0) {
      const { data } = yield call(getLanguagesList);
      languages = data;
    }

    yield put(getLanguagesSuccess(languages));
  } catch (e) {
    const { data } = e.response;
    console.log(data)
    yield put(getLanguagesFailure(data.detail));
  }
}

function* getAvailableSalons(action) {
  const { params, digitalSalonTable } = action.payload;
  if (params.free_text) yield put({ type: "VALUE_NOT_EMPTY" });

  try {
    const { data } = yield call(getAvailableSalonsList, params);

    if (digitalSalonTable) {
      const dataWithFreeSpaceProp = data.results.map((salon) => ({
        ...salon,
        free_space:
          salon.max_guests -
          salon.invites_pending_count -
          salon.invites_confirmed_count,
      }));
      const filteredData = dataWithFreeSpaceProp.filter(
        (salon) => salon.free_space > 0
      );
      const digitalSalonsList = filteredData,
        digitalSalonsCountAllPages = data.count,
        totalDigitalSalonsAmountNoFilters = data.total_no_filters;
      yield put(
        getDigitalSalonsSuccess({
          digitalSalonsList,
          digitalSalonsCountAllPages,
          totalDigitalSalonsAmountNoFilters,
          ...getFiltersStrings(data.next, data.previous),
        })
      );
    } else {
      yield put(getAvailableSalonsListSuccess(data));
    }

    yield put({ type: "REQUEST_SUCCESS" });
  } catch (e) {
    const errorText: string = e.message;

    yield put(getAvailableSalonsListFail(errorText));
    yield call(() => redirectToErrorPage());
  }
}

function* getGuestProfileById(action) {
  try {
    const { data } = yield call(() => getGuestProfileCall(action.payload));
    let finalData = data;

    if (data.phone) {
      const isIsraeliPhoneNumber: boolean =
        data.phone.startsWith("+972") || data.phone.startsWith("0");
      let mobileOrPhoneNumber: number;

      

      const dataToArray = [data];

      const guestProfileWithPhonePrefix = dataToArray.map((guest) => ({
        ...guest
      }));
      finalData = { ...guestProfileWithPhonePrefix }[0];
    }

    yield put(actions.getGuestProfileSuccess(finalData));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* updateGuestProfile(action) {
  try {
    const { guestId, guestUpdateData, changeFromHost } = action.payload;
    const { data } = yield call(() =>
      updateGuestProfileCall(guestId, guestUpdateData)
    );

    yield put(actions.updateGuestProfileSuccess(data));
    if (changeFromHost) {
      yield put(
        registerGuestSuccess({
          guest: data.id,
          guest_registration_status: data.registration_status,
        })
      );
      yield call(() =>
        history.push(buildPath(routes.guestProfile.salon, { id: data.id }))
      );
    }
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getGuestSalonById(action) {
  try {
    const guestId = action.payload;
    const { data } = yield call(() => getGuestSalonCall(guestId));

    const arrayToObject = (data) =>
      Object.assign({}, ...data.map((item) => ({ ...item })));

    yield put(actions.getGuestSalonByIdSuccess(arrayToObject(data)));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* deleteGuestSalon(action) {
  try {
    yield call(() => deleteGuestSalonCall(action.payload));
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* updateGuestSalon(action) {
  const { salonId, guestId, guestSalonUpdated, cb } = action.payload;
  try {
    yield call(() => updateGuestSalonCall(salonId, guestSalonUpdated));
    const { data } = yield call(() => getGuestSalonCall(guestId));

    const arrayToObject = (data) =>
      Object.assign({}, ...data.map((item) => ({ ...item })));

    yield put(actions.updateGuestSalonSuccess(arrayToObject(data)));
    yield call(cb);
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* createGuestSalon(action:any) {
  const { data, cb } = action?.payload;
  try {
    yield call(() => createGuestSalonCall(data));
    yield call(cb);
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getAllGuests(action) {
  try {
    yield put({ type: guestActionTypes.GET_GUESTS_LIST_LOADING });
    const { payload } = action;
    const { data } = yield call(() => getAllGuestsCall(payload));
    const guestsList = data.results,
      guestCountAllPages = data.count,
      totalGuestsAmountNoFilters = data.total_no_filters;

    yield put(
      getGuestsListSucsses({
        guestsList,
        guestCountAllPages,
        totalGuestsAmountNoFilters,
        ...getFiltersStrings(data.next, data.previous),
      })
    );
  } catch (e) {
    const errorArray = sagaSetErrorHelperFn(e);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* getAllGuestsList(action) {
  const { payload } = action;
  try {
    const params = { ...payload, pagination: false };
    const { data } = yield call(() => getAllGuestListCall(params));
    yield put(getAllGuestsListSuccess(data));
  } catch (e) {
    action.payload.callback(false);
  }
}

function* guestSaga() {
  yield takeLatest(salonSearchMapActionTypes.GET_LANGUAGES, getGuestLanguages);
  yield takeLatest(
    salonSearchMapActionTypes.GET_AVAILABLE_SALONS_LIST_INIT,
    getAvailableSalons
  );
  yield takeLatest(guestActionTypes.GET_GUEST_BY_ID, getGuestProfileById);
  yield takeLatest(guestActionTypes.UPDATE_GUEST_PROFILE, updateGuestProfile);
  yield takeLatest(guestActionTypes.GET_GUEST_SALON, getGuestSalonById);
  yield takeLatest(guestActionTypes.DELETE_GUEST_SALON, deleteGuestSalon);
  yield takeLatest(guestActionTypes.UPDATE_GUEST_SALON, updateGuestSalon);
  yield takeLatest(guestActionTypes.CREATE_GUEST_SALON, createGuestSalon);
  yield takeLatest(guestActionTypes.GET_ALL_GUESTS, getAllGuests);
  yield takeLatest(guestActionTypes.GET_ALL_GUESTS_LIST, getAllGuestsList);
}

export default guestSaga;
