import React, { useState } from "react";
import "./style.css";
import Modal from "react-modal";
import ButtonSmall from "../../../common/ButtonSmall";
import { Link, useHistory } from "react-router-dom";
import { Textarea } from "../../../common/Input/formInputStyle";
import { useTranslation } from "react-i18next";
import { buildPath, routes } from "../../../../utils/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import * as hostsSelectors from "../../../../store/selectors/hostsSelectors";
import { colorLighterGrey } from "../../../../utils/css/colors";
import { updateHostSalonCall } from "../../../../store/apiCalls/hosts";
import MicroCopyHostPopup from "../../../../utils/microCopyHostPopup";
import getDirection from "../../../../utils/getLanguageDirection";
import useIsMobile from "../../../../utils/usIsMobile";
import { bodyFont } from "../../../../utils/css/fonts";

const RegisterPopup = (props: any) => {  
  const { t } = useTranslation();
  const lang= getDirection();
  const [guestComment, setGuestComment] = useState(props.values && props.values().comments_for_guests);
  const history = useHistory();
  const hostId = useSelector(hostsSelectors.hostIdSelector);
  const isMobile = useIsMobile();
  const closeModal = () => { 
    props.setPopupIsOpen(false);
    props.closeModal();
    history.push(buildPath(routes.hostProfile.salon, { id: hostId }));
  };

  const customStylesPopup = {
    content: {
      width: isMobile ? "350px": "780px",
      position: "absolute",
      height: "517px",
      top: "50%",
      overflow: "none",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "white",
      zIndex: "110",
      textAlign: "center",
      direction: "rtl",
      padding: "0",
      fontFamily: bodyFont,
      border: "none",
    },
  };

  const navigateToSalonDetails = () => history.push(buildPath(`/site/guest/${props.id}/salon`));

  const salon = props?.salon;

  const isIsraeliPhoneNumber: boolean =
  salon?.host_phone?.startsWith("+972") || salon?.host_phone?.startsWith("0");
  const displayPhone = isIsraeliPhoneNumber ? "0" + salon?.host_phone?.substring(4) : salon?.host_phone;
  return (
    <>
      <Modal
        isOpen={props?.popupIsOpen}
        onRequestClose={() => {}}
        style={customStylesPopup}
        contentLabel="Modal"
        ariaHideApp={false}
      >
        <div style={props.style} className="success-container" dir={lang}>
          <img src={props.icon} alt="success" className="success-icon"></img>
          <p className="success-header"> {props.header}</p>
          <div className="details-content">
            <p
              className={
                props.isNewSalon ? "popup-success-subtitle" : "details-header"
              }
            >
              {props.subHeader}
            </p>
            {props.isNewSalon ? (
              <>
                <Textarea
                  widthOfInput="100%"
                  maxLength={200}
                  minHeight="110px"
                  placeholder={t("host.commentForGuestsPlaceholder")}
                  name="comments_for_guests"
                  border="1px solid rgba(204, 204, 204, 0.63)"
                  padding="10px"
                  onChange={(e) => setGuestComment(e.target.value)}
                  ref={props.register}
                />
                <p
                  style={{
                    textAlign: "start",
                    color: colorLighterGrey,
                    fontSize: "15px",
                  }}
                >
                  {t("host.maxLetters")}
                </p>
              </>
            ) : (
              <>
              <div> {t("host.hostDetails")}
                <div className="host-salon-details-popup">
                  {salon?.host_name} <div></div>{" "}{displayPhone} <div></div>{" "} {salon?.host_email}
                </div>
                {salon?.country =='IL' 
                ?<p className="more-details-content">{t("host.moreDetails")}</p>  
                :<p className="more-details-content">{t("host.moreDetailsSalonNoIL")}</p>}
                </div>
              </>
            )}
          </div>
          <div className="action-btns-popup">
            <ButtonSmall
              width={props.isNewSalon ? 165 : 204}
              height={"54px"}
              colorType={"#ffda4c"}
              colorProps={"black"}
              borderProps={"none"}
              position={"relative"}
              disabled={props.isNewSalon ? guestComment === "" : false}
              fontSize={"18px"}
              onClick={
                props.isNewSalon 
                ? async () => {
                      await updateHostSalonCall(props.salonId, {
                        comments_for_guests: guestComment,
                      });
                      props.getHostSalonDetails(props.salonId);
                      closeModal();
                    }
                : navigateToSalonDetails
                }
            >
              {props.isNewSalon 
              ? <p style={{ fontSize: "17px" }}>{t("guests.save")}</p> 
              : <p style={{ fontSize: "17px" }}> {t("host.hostProfile.backToMySalon")}</p>
              }
            </ButtonSmall>
            <div style={{display: 'flex',flexDirection: isMobile ? 'row-reverse' : "row"}}>
            {props.isNewSalon && (
              <ButtonSmall
                onClick={closeModal}
                fontSize={"16px !important"}
                width={100}
                colorProps={"black"}
                colorType={"transparent"}
                textDecoration={"underline"}
                marginInlineStart={"20px"}
              >
                {t("host.hostProfile.nextTime")}
              </ButtonSmall>
            )}
            {props.isNewSalon && (
              <div style={{ display: "flex", alignItems: "center",marginInlineStart: '35px' }}>
              <MicroCopyHostPopup
                  text={t("host.hostExclamationMark.successPopup")}
                  popupTextStyle={{ width: isMobile ? '200' : '335', top: 0, bottom:  0 }}
                />
              </div>
            )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegisterPopup;
