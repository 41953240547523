import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useLogout from "../../../common/useLogout";
import hamburger from "../../../../assets/icons/hamburgerIcon.svg";
import {
  LanguageChoicesContainer,
  LogoutButton,
  MenuContainer,
  NavBarContainer,
  NavBarOptions
} from "./styles"
import NavBarActionsHost from "../../../Admin/Header/NavBarActionHost";
import LanguageToggle from "../../../common/LanguageToggle/LanguageToggle";

const HamburgerMenu = ({history}) => {
  const { t } = useTranslation();
  const [ isVisible, toggleIsVisible] = useState(false);
  const logout = useLogout();
  return (
    <div>
    <img src={hamburger} alt="user logged in options" onClick={() => toggleIsVisible(!isVisible)}/>
    {isVisible && 
    <MenuContainer>
      <NavBarContainer>
        <NavBarOptions>
          <NavBarActionsHost history={history} onClickMenuVisible={toggleIsVisible}/>
        </NavBarOptions>
        <LogoutButton onClick={logout}>
          {t("admin.header.userItems.logout")}
        </LogoutButton>
      </NavBarContainer>
      <LanguageChoicesContainer>
        <LanguageToggle/>
      </LanguageChoicesContainer>
    </MenuContainer>}
    </div>
  );
};

export default HamburgerMenu;
