import styled, { css } from "styled-components/macro";
import { body12, body14 } from "../../../utils/css/typography";
import { colorPink } from "../../../utils/css/colors";
import { mobile } from "../../../utils/css/media";

interface IFlexDivProps {
  flexDirection?: string;
  heightDiv?: string;
  widthOfDiv?: string;
  justifyContent?: string;
  flex?: string;
  flexWrap?: string;
  alignItems?: string;
  isColumn?: boolean;
  flexDir?: string;
  gapBetween?: string;
}

interface IFieldContainerProps {
  fullWidthField?: boolean;
  isFlexBasis?: string;
  widthOfField?: string;
  padding?: string;
  minWidth?: number;
  isWordBreak?: boolean;
  height?: string;
}

export const FieldContainer = styled.div<IFieldContainerProps>`
  display: flex;
  padding: ${({ padding }) => (padding ? padding : "0")};
  align-items: baseline;
  width: ${({ fullWidthField }) => fullWidthField && "100%"};
  max-width: ${({ widthOfField }) => (widthOfField ? widthOfField : "")};
  flex-basis: ${({ isFlexBasis }) => (isFlexBasis)};
  word-break: ${({ isWordBreak }) => (isWordBreak ? "break-all" : "unset")};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}%` : "unset")};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
  & > div {
    width: ${({ fullWidthField }) => fullWidthField && "100%"};
  }

  & > .react-datepicker-popper {
    position: sticky !important;
  }

  ${mobile(css`
    & > div {
      width: 100%;
    }
  `)};
  .bgfhGS{
    width:384px
  }
  .jROgEA{
    padding: 9px 0 0 0;
  }
  .eNcSHo{
    margin-right: 20px;
        height: 63px;
  }
  .dISKbN{
    margin:0;
  }
  
`;

export const ColoredUnderSpan = styled.div<{
  isVisible: boolean | undefined;
  colorUnderSpanPosition?: string;
  colorUnderSpanTop?: string;
}>`
  color: ${colorPink};
  font-weight: 500;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  position: ${({ colorUnderSpanPosition }) => colorUnderSpanPosition};
  top: ${({ colorUnderSpanTop }) => colorUnderSpanTop};
  ${body12};
`;

export const FieldTitle = styled.div<{
  flex?: string;
  isWidthField?: string;
  isBold?: boolean;
  alignCenter?: boolean;
  fieldTitlePosition?: string;
  fieldTitleTop?: string;
  fieldTitleRight?: string;
}>`
  padding-inline-end: 0.7rem;
  flex: ${({ flex }) => (flex ? flex : "unset")};
  width: ${({ isWidthField }) => (isWidthField ? isWidthField : "unset !important")};
  font-weight: ${({ isBold }) => (isBold ? "bold" : "700")};
  word-break: initial;
  white-space: nowrap;
  position: ${({ fieldTitlePosition }) => fieldTitlePosition};
  top: ${({ fieldTitleTop }) => fieldTitleTop};
  right: ${({ fieldTitleRight }) => fieldTitleRight};
  ${body14};
  align-self: ${({ alignCenter }) => (alignCenter ? "center" : "")};
  font-size: 20px;
  opacity: 0.6;
  margin-bottom: 10px;
`;

export const FlexDiv = styled.div<IFlexDivProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "unset"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "nowrap")};
  position: relative;
  height: ${({ heightDiv }) => (heightDiv ? heightDiv : "")};
  width: ${({ widthOfDiv }) => (widthOfDiv ? widthOfDiv : "")};
  flex: ${({ flex }) => (flex ? flex : "unset")};
  flex-direction: ${({ flexDir }) => flexDir};
  gap: ${({ gapBetween }) => gapBetween || "unset"};
  ${mobile(css<IFlexDivProps>`
    flex: 50%;
    justify-content: ${({ isColumn }) => (isColumn ? "" : "space-between")};
    flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  `)}
`;

export const RequireSign = styled.span`
  color: ${colorPink};
`;

export const FlexColumnField = styled.div.attrs({
  className: "flexColumnField",
}) <{
  widthOfDiv?: string;
  position?: string;
  flexColumnFieldPosition?: string;
  display?: string;
  fontSize?: string;
  marginTop? :string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: 30px;
  margin-top: ${({ marginTop }) => (marginTop || "27px")};
  display: ${({ display }) => (display ? display : "flex")};
  width: ${({ widthOfDiv }) => (widthOfDiv ? widthOfDiv : "100%")};
  position: ${({ flexColumnFieldPosition }) => flexColumnFieldPosition};
`;
