export enum authActionTypes {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",

  GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST",
  GOOGLE_LOGIN_REQUEST_SUCCESS = "GOOGLE_LOGIN_REQUEST_SUCCESS",
  GOOGLE_LOGIN_REQUEST_FAILURE = "GOOGLE_LOGIN_REQUEST_FAILURE",
  ORIGIN_REQUEST = "ORIGIN_REQEUST",
  ORIGIN_REQUEST_SUCCESS = "ORIGIN_REQUEST_SUCCESS",
  
  LOGOUT = "LOGOUT",

  SILENT_LOGIN = "SILENT_LOGIN",

  FORGOT_PASSWORD_LOGIN = "FORGOT_PASSWORD_LOGIN",

  CONFIRM_POLICY_REQUEST = "CONFIRM_POLICY_REQUEST",
  CONFIRM_POLICY_SUCCESS = "CONFIRM_POLICY_SUCCESS",
  CONFIRM_POLICY_FAILURE = "CONFIRM_POLICY_FAILURE",

  USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST",
  USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS",
  USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE",

  REGISTER_USER = "REGISTER_USER",
  REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE",

  REGISTER_GUEST = "REGISTER_GUEST",
  REGISTER_GUEST_SUCCESS = "REGISTER_GUEST_SUCCESS",
  REGISTER_GUEST_FAILURE = "REGISTER_GUEST_FAILURE",

  REGISTER_HOST = "REGISTER_HOST",
  REGISTER_HOST_SUCCESS = "REGISTER_HOST_SUCCESS",
  REGISTER_HOST_FAILURE = "REGISTER_HOST_FAILURE",

  SEND_LOGIN_SMS_CODE_REQUEST = "SEND_LOGIN_SMS_CODE_REQUEST",
  SEND_LOGIN_SMS_CODE_SUCCESS = "SEND_LOGIN_SMS_CODE_SUCCESS",
  SEND_LOGIN_SMS_CODE_FAILURE = "SEND_LOGIN_SMS_CODE_FAILURE",

  LOGIN_CODE_VERIFICATION_REQUEST = "LOGIN_CODE_VERIFICATION_REQUEST",
  LOGIN_CODE_VERIFICATION_SUCCESS = "LOGIN_CODE_VERIFICATION_SUCCESS",
  LOGIN_CODE_VERIFICATION_FAILURE = "LOGIN_CODE_VERIFICATION_FAILURE",

  OPEN_CHANGE_ROLES_MODAL = "OPEN_CHANGE_ROLES_MODAL",
  CLOSE_CHANGE_ROLES_MODAL = "CLOSE_CHANGE_ROLES_MODAL",

  CONVERT_USER_SELECTED_REQUEST = "CONVERT_USER_SELECTED_REQUEST",

  CHANGE_HOST_TO_GUEST_REQUEST = "CHANGE_HOST_TO_GUEST_REQUEST",
  CHANGE_HOST_TO_GUEST_SUCCESS = "CHANGE_HOST_TO_GUEST_SUCCESS",
  CHANGE_HOST_TO_GUEST_FAILURE = "CHANGE_HOST_TO_GUEST_FAILURE",

  CHANGE_GUEST_TO_HOST_REQUEST = "CHANGE_GUEST_TO_HOST_REQUEST",
  CHANGE_GUEST_TO_HOST_SUCCESS = "CHANGE_GUEST_TO_HOST_SUCCESS",
  CHANGE_GUEST_TO_HOST_FAILURE = "CHANGE_GUEST_TO_HOST_FAILURE",

  SAVE_HOST_REGISTRATION_DETAILS = "SAVE_HOST_REGISTRATION_DETAILS",
  CLEAR_HOST_REGISTRATION_DETAILS = "CLEAR_HOST_REGISTRATION_DETAILS",

  UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE",
}
