import React from 'react';
import { useSelector } from 'react-redux';
import * as authSelector from '../../../store/selectors/authSelectors';
import { useTranslation } from 'react-i18next';
import history from '../../../history';
// components
import useLanguage from '../../common/useLanguage';
import useLogout from '../../common/useLogout';
// utils
import contactUsUrl from '../../../utils/constants/siteUrls';
import {
  FlexRow,
  Logo as MenuLogo,
  TextLink,
  Row,
  FlexColumn,
  ZikaronBasalonWelcome,
} from '../CommonStyles';
import { Logo } from '../Login/loginStyle';
import { ActionItem, TextItem, StyledAnchor } from '../../Admin/Header/style';
import smallRightBlackArrow from '../../../assets/icons/smallRightBlackArrow.svg';
import zikaronBasalonWelcome from '../../../assets/icons/zikaronBasalonWelcome.svg';

// @ts-ignore
import envelope from '../../../assets/icons/whiteEnvelope.svg';
import { 
  MenuItems,
  ContactUs
} from './styles';
import LanguageToggle from '../../common/LanguageToggle/LanguageToggle';
interface IProps {
  showHomePageSection?: boolean;
  showBackToHomePage?: boolean;
}

const Desktop: React.FC<IProps> = ({
  showHomePageSection,
  showBackToHomePage,
}) => {
  const { t } = useTranslation();
  const logout = useLogout();

  const first_name = useSelector(authSelector.firstNameSelector);
  const last_name = useSelector(authSelector.lastNameSelector);
  const token = useSelector(authSelector.tokenSelector);

  const [selectedLanguage, onLanguageChange] = useLanguage();
  const navigateToHomePage = () => history.push('/');

  return (
    <>
      <FlexRow showHomePageSection={showHomePageSection}>
        <div>
          {token !== null && showHomePageSection ? (
            <Row>
              <MenuLogo onClick={navigateToHomePage}>
                {t('admin.header.logo')}
              </MenuLogo>

              <ActionItem>{`${t(
                'admin.header.userItems.greetings'
              )} ${first_name} ${last_name}`}</ActionItem>
              <ActionItem clickable onClick={logout}>
                {t('admin.header.userItems.logout')}
              </ActionItem>
            </Row>
          ) : showHomePageSection ? (
            <div>
              <MenuLogo>{t('admin.header.logo')}</MenuLogo>
              {showBackToHomePage && (
                <TextLink to="/">
                  <Row lang={selectedLanguage}>
                    <img src={smallRightBlackArrow} alt="Back to home page" />
                    <span>{t('formButtons.backToHomePage')}</span>
                  </Row>
                </TextLink>
              )}
            </div>
          ) : null}
        </div>
        <MenuItems>
          <ActionItem>
            <Row>
              <ContactUs
                href={selectedLanguage==="he" ? 'https://www.zikaronbasalon.com/צרו-קשר/' : contactUsUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('admin.header.makeContact')}
              </ContactUs>
            </Row>
          </ActionItem>
          <LanguageToggle/>
        </MenuItems>
      </FlexRow>
      <FlexColumn>
        {!showHomePageSection && <Logo>{t('login.welcome')}</Logo>}
        <ZikaronBasalonWelcome src={zikaronBasalonWelcome} />
      </FlexColumn>
    </>
  );
};

export default Desktop;
