import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import history from "./history";
import store, { persistor } from "./store";
import Routes from "./components/Routes";
import "./utils/css/fonts.css";
import { GlobalStyle } from "./theme";
import { setAxiosHeader } from "./store/middleware/auth.middleware";
import "./matrielUiStyles.css";
import { localStorageAuthKey } from "./utils/constants/auth";
import ErrorBoundary from "./components/common/ErrorBoundary/ErrorBoundary";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App: React.FC = () => {
  useEffect(() => {
    const authReducer = JSON.parse(localStorage.getItem(localStorageAuthKey));
    if (authReducer !== null) {
      // if token exist, set header and remove "" from token
      if (JSON.parse(authReducer.token))
        setAxiosHeader(authReducer.token.replace(/['"]+/g, ""));
    }
  }, []);

  return (
    <GoogleOAuthProvider
      clientId={
        process.env.REACT_APP_GOOGLE_OAUTH2_KEY || "292301160628-3c1ject1td2muiikjeq455f6b2urn8ao.apps.googleusercontent.com"
      }
    >
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyle />
            <Router history={history}>
              <Routes />
            </Router>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </GoogleOAuthProvider>
  );
};

export default App;
