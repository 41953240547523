export enum salonSearchMapActionTypes {
  GET_LANGUAGES = "GET_LANGUAGES",
  GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS",
  GET_LANGUAGES_FAILURE = "GET_LANGUAGES_FAILURE",

  GET_AVAILABLE_SALONS = "GET_AVAILABLE_SALONS",
  GET_AVAILABLE_SALONS_LIST_INIT = "GET_AVAILABLE_SALONS_LIST_INIT",
  GET_AVAILABLE_SALONS_LIST_SUCCESS = "GET_AVAILABLE_SALONS_LIST_SUCCESS",
  GET_AVAILABLE_SALONS_LIST_FAIL = "GET_AVAILABLE_SALONS_LIST_FAIL",
  GET_AVAILABLE_DIGITAL_SALONS_TABLE_SUCCESS = "GET_AVAILABLE_DIGITAL_SALONS_TABLE_SUCCESS ",
}
