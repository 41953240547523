import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import * as authSelector from "../../../store/selectors/authSelectors";
import { buildPath } from "../../../utils/constants/routes";
import useIsMobile from "../../../utils/usIsMobile";
import { NavBarActionItemContainer, NavBarActionItemMobile, SelectedActionItemDot, TextItem, Underline } from "./style";

type TActionItems = {
  id: NavBarItem;
  text: string;
  isSelected: boolean;
};
type NavBarItem = "salon" | "profile";

const profileId = "profile";
const NavBarOptions: TActionItems[] = [
  {
    id: "salon",
    text: "guests.mySalon",
    isSelected: true,
  },
  {
    id: profileId,
    text: "host.profile",
    isSelected: false,
  },
];

const NavBarActionsHost = ({ history, onClickMenuVisible = null }) => {
  const token = useSelector(authSelector.tokenSelector);
  const isMobile = useIsMobile();
  const role = useSelector(authSelector.roleSelector);

  const [actionItems, setActionItems] = useState<TActionItems[]>(NavBarOptions);

  useEffect(() => {
    const path = history?.location?.pathname;
    const lastPart = path?.split("/")?.pop();
    setActionItems((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        isSelected: option.id === lastPart,
      }))
    );
  }, [history]);

  const { hostId } = useSelector(
    ({ hostsReducer: { hostId } }: RootState) => ({
      hostId,
    })
  );
  const { guestId } = useSelector(
    ({ guestsReducer: { guestId } }: RootState) => ({
      guestId,
    })
  );
  const { t } = useTranslation();

  const handleNavigate = (itemId: TActionItems | any) => {
    history.push(
      buildPath(`/site/${role}/${role==="host" ? hostId : guestId}/${itemId}`)
    );
    const newActionItems = [...actionItems];
    newActionItems.forEach((item) => {
      item.isSelected = item.id === itemId;
    });
    setActionItems(newActionItems);
    isMobile && onClickMenuVisible(false);
  };
  return (
    <>
      {actionItems.map((item: TActionItems) => (
        <NavBarActionItemContainer key={item.id} onClick={() => handleNavigate(item.id)}>
          {isMobile ?
          <NavBarActionItemMobile>
            {item.isSelected && <SelectedActionItemDot/>}
            <TextItem isSelected={item.isSelected}>{t(item.text)}</TextItem>
          </NavBarActionItemMobile> :
          <>
            <TextItem isSelected={item.isSelected}>{t(item.text)}</TextItem>
            {item.isSelected &&  <Underline />}
          </>
          }
        </NavBarActionItemContainer>
      ))}
    </>
  );
};

export default NavBarActionsHost;