import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import adminReducer from "./admin.reducer";
import witnessesReducer from "./witnesses.reducer";
import hostsReducer from "./hosts.reducer";
import salonsReducer from "./salons.reducer";
import guestsReducer from "./guests.reducer";
import errorReducer from "./error.reducer";

export const rootReducer = combineReducers({
  authReducer,
  adminReducer,
  witnessesReducer,
  hostsReducer,
  salonsReducer,
  guestsReducer,
  errorReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
