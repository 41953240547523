import styled from "styled-components";
import { mobileHeaderHeight } from "../../../../utils/css/sizes";
import { isIOS } from "react-device-detect";

export const Header = styled.div<{ lng: string; isIOSModalOpen: boolean, isLoggedIn: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: ${({ lng }) => (lng === "he" ? "rtl" : "ltr")};
  gap: 21px;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    margin-bottom: ${({ isLoggedIn }) =>(isLoggedIn ? '-90px' : '-70px')};
  }
`;
export const HeaderMenu = styled.div<{isLoggedIn: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 34px 20px 18px 20px;
  height: ${mobileHeaderHeight}px;
  box-shadow: ${({isLoggedIn}) => isLoggedIn? '0px 4px 14px 0px #0000001A': 'none'};
  position: ${({isLoggedIn}) => 'relative'};
  z-index: 100;
  background-color: ${({isLoggedIn}) => isLoggedIn && '#fff'};;
  width: 100%;
`;

export const MenuContainer = styled.div`
  position: absolute;
  width: 100vw ;
  top: ${mobileHeaderHeight}px;
  height: calc(100vh - ${mobileHeaderHeight}px) ;
  left: 0 ;
  background: #fff ;
  z-index: 100;
  padding: 0 20px;  
  font-size: 18px;
  padding-top: 43px;
  display: flex;
  flex-direction: column;
`;

export const HeaderLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavBarContainer = styled.div`
display: flex;
flex-direction: column ;
justify-content: space-between;
padding-bottom: 22px;
border-bottom: 1px solid #E1E1E1;
border-top: 1px solid #E1E1E1;
flex: 3;
`;

export const NavBarOptions = styled.div`
display: flex;
flex-direction: column ;
width: 100%;
`;

export const LogoutButton = styled.div`
  border-bottom: 1px solid;
  line-height: 20px;
  width: fit-content;
`;

export const LanguageChoicesContainer = styled.div`
  padding-top: 10px;
  flex: 1;
`;