import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import rootReducer from "./reducers";
import rootSaga from "./saga";
import { addRequestObject } from "./middleware/auth.middleware";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware, addRequestObject))
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
