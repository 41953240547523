import { instance as axios } from '../middleware/auth.middleware';

const guestsUrl = '/api/guests';
const guestsSalonsUrl = '/api/salonguests';
const availableSalonsUrl = 'api/host_salons_map';

export const getLanguagesList = () => axios.get(`/api/languages/`);

export const getAvailableSalonsList = (params) =>
  axios.get(`${availableSalonsUrl}/`, { params });

export const createGuestcall = (guestId: number, data: any) =>
  axios.patch(`${guestsUrl}/${guestId}/`, data);

export const getGuestProfileCall = (guestId: string) =>
  axios.get(`${guestsUrl}/${guestId}/`);

export const getGuestSalonCall = (guestId: number) =>
  axios.get(`${guestsSalonsUrl}/`, {
    params: { guest: guestId, denied: false },
  });

export const updateGuestProfileCall = (guestId: string, guestData: any) =>
  axios.patch(`${guestsUrl}/${guestId}/`, guestData);

export const deleteGuestSalonCall = (salonId: string) =>
  axios.delete(`${guestsSalonsUrl}/${salonId}/`);

export const updateGuestRequestCall = (requestId: number, data) =>
  axios.patch(`${guestsSalonsUrl}/${requestId}/`, data);

export const updateGuestSalonCall = (salonId: string, salonData: any) =>
  axios.patch(`${guestsSalonsUrl}/${salonId}/`, salonData);

export const createGuestSalonCall = (data: any) =>
  axios.post(`${guestsSalonsUrl}/`, data);

export const getAllGuestsCall = (params) =>
  axios.get(`${guestsUrl}/`, { params });

//Get all salons list For export

export const getAllGuestListCall = (params) => {
  if (params) {
    axios.get(`${guestsUrl}/`, { params });
  } else {
    axios.get(`${guestsUrl}/?pagination=false`);
  }
};
