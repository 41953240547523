import styled from "styled-components";
import { StyledAnchor } from '../../Admin/Header/style';

export const MenuItems = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

export const ContactUs = styled(StyledAnchor as 'a')`
  border-bottom: 1px solid;
  line-height: 15px;
`;