import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import * as authSelector from "../../../../store/selectors/authSelectors";
import * as hostsSelector from "../../../../store/selectors/hostsSelectors";
// components
import useLanguage from "../../../common/useLanguage";
import HamburgerMenu from "./HamburgerMenu";
import LanguageToggle from "../../../common/LanguageToggle/LanguageToggle";
// utils
import { Logo } from "../../Login/loginStyle";
import { BaseItem, ZikaronLogo } from '../../../Admin/Header/style';
import zikaronLogo from '../../../../assets/icons/zikaronLogo.svg';
import useIsUserAdmin from "../../../../utils/useIsUserAdmin";
import contactUsUrl from "../../../../utils/constants/siteUrls";
import * as salonsSelector from "../../../../store/selectors/salonsSelectors";
import { isIOS } from "react-device-detect";
import {
  MenuItems,
  ContactUs
} from "../styles"
import {
  Header,
  HeaderMenu,
  HeaderLogoContainer,
} from "./styles"

const Mobile = ({ history }) => {
  const { t } = useTranslation();
  const [selectedLanguage, onLangugageChange] = useLanguage();

  const token = useSelector(authSelector.tokenSelector);
  const isIOSModalOpen = useSelector(salonsSelector.isIOSModalOpenSelector);
  const firstName = useSelector(authSelector.firstNameSelector);
  const lastName = useSelector(authSelector.lastNameSelector);
  const role = useSelector(authSelector.roleSelector);
  const hostProfile = useSelector(hostsSelector.hostProfileSelector);

  const fullName = (hostProfile?.first_name && hostProfile?.last_name)
    ? `${hostProfile.first_name} ${hostProfile.last_name}`
    : (firstName && lastName) ? `${firstName} ${lastName}` :
      t(`admin.header.userItems.${role}`)

  const isLoggedIn = token !== null;
  const isUserAdmin = useIsUserAdmin();
  const handleToggleLanguage = () => {
    return selectedLanguage === "he"
      ? onLangugageChange("en")
      : selectedLanguage === "en"
        ? onLangugageChange("he")
        : null;
  };

  const openUrlOnNewTab = () => {
    window.open('https://www.zikaronbasalon.com', '_blank');
  }
  
  return (
    <Header lng={selectedLanguage} isIOSModalOpen={isIOSModalOpen} isLoggedIn={isLoggedIn}>
      <HeaderMenu isLoggedIn={isLoggedIn}>
        {/* {isLoggedIn && isUserAdmin && <HamburgerMenu />} */}
        <HeaderLogoContainer>
          <ZikaronLogo src={zikaronLogo} alt="logo" onClick={() => openUrlOnNewTab()}
          ></ZikaronLogo>
          {isLoggedIn &&
            <BaseItem>
              {`${t("admin.header.userItems.greetings")} ${token && fullName
                }`}
            </BaseItem>
          }
        </HeaderLogoContainer>
        {isLoggedIn ? (
          <HamburgerMenu history={history} />
        ) : (
          <MenuItems>
            <ContactUs
              href={selectedLanguage === "he" ? 'https://www.zikaronbasalon.com/צרו-קשר/' : contactUsUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('admin.header.makeContact')}
            </ContactUs>
            <LanguageToggle />
          </MenuItems>
        )}
      </HeaderMenu>
      {!isLoggedIn && <Logo>{t('login.welcome')}</Logo>}
    </Header>
  );
};

export default withRouter(Mobile);
