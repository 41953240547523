import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { IGoogleMapProps } from "../../../interfaces/common";
import { Marker, UserMarker } from "./components/Markers/Markers";
import SalonSearchLeftDrawerBar from "../../Guests/SalonSearchMap/SalonSearchLeftDrawerBar";
import useLanguage from "../useLanguage";

export const GoogleMap: React.FC<IGoogleMapProps> = ({
  markers,
  guestPosition,
  onMarkerClick,
  center
}) => {  
  const [lang, _setLang] = useLanguage();
  const [mapCenter, setMapCenter] = useState({
    isCenter: false,
    center: { lat: 0, lng: 0},
    zoom: 100,
  })
  const [defaultCenter, setDefaultCenter] = useState({
    center: { lat: +32.072788, lng: +34.779400},
    zoom: 15,
  });

  const updateMapCenter = ( lat?: number, lng?: number) => {
    if(lat && lng) {
      setMapCenter((mapCenter) => ({
        ...mapCenter,
        center:{ lat, lng},
        isCenter: true,
        zoom: 100
      }))
    }
  }
  
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);
  const [selectedMarker, setSelectedMarker] = React.useState(null);


  const handleMarkerClick = (marker:any, idx: number) => {
    onMarkerClick(idx, marker.id);
    if(selectedMarker && marker.id === selectedMarker.id) {
      setSelectedMarker(null)
    } else{
      updateMapCenter(marker.lat, marker.lng);
      setSelectedMarker(marker)
    }
  }

  useEffect(() => {
    if (guestPosition.lng && guestPosition.lng != '0.000000000000000' && guestPosition.lat && guestPosition.lat != '0.000000000000000' ) {
      setDefaultCenter((defaultCenter) => ({
        ...defaultCenter,
        center: { lat: +guestPosition.lat, lng: +guestPosition.lng },
      }));
    }   
  }, [guestPosition]);

  useEffect(()=>{
    if (center){
        setMapCenter((mapCenter) => ({
          ...mapCenter,
          center:center,
          isCenter: true,
          zoom: 100
        }));
    }
  },[center])

  const [draggable, setDraggable] = useState(true);
  const guestLatLng =
  guestPosition && typeof google !== "undefined" && google.maps && new google.maps.LatLng(+guestPosition.lat, +guestPosition.lng);
  
  return (
    <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_KEY || "AIzaSyCSVSuUVhz6XBgyQ3R0W3NTuzl8p05N8gI",
            language: lang,
            libraries: ["places"],
          }}
          options={{ minZoom: 8, fullscreenControl: false }}
          draggable={draggable}
          onChildMouseDown={(key) => key !== "userMarker" && setDraggable(false)}
          onClick={() => setDraggable(true)}
          defaultCenter={defaultCenter.center}
          defaultZoom={defaultCenter.zoom}
          center={mapCenter.isCenter ? mapCenter.center : defaultCenter.center}
          yesIWantToUseGoogleMapApiInternals={true}
        >
          {markers &&
            markers.map((marker, idx) => (
              <Marker
              isCenter={
                (center && mapCenter.center.lat === marker.lat && mapCenter.center.lng === marker.lng) ||
                (selectedMarker && selectedMarker.id === marker.id)
              }
              key={marker.id}
              onMarkerClick={() => {handleMarkerClick(marker, idx)}      
            }
              lat={marker.lat}
              lng={marker.lng}
              markerInfo={marker.markerInfo}
              setSideDrawerOpen={setSideDrawerOpen}
              />
              ))}
          <UserMarker
            key={"userMarker"}
            lat={defaultCenter.center.lat}
            lng={defaultCenter.center.lng}
            />
        </GoogleMapReact>
        <SalonSearchLeftDrawerBar
          selectedSalonDetails={selectedMarker?.markerInfo}
          guestLatLng={guestLatLng}
          modalIsOpen={sideDrawerOpen}
          setIsOpen={setSideDrawerOpen}
        ></SalonSearchLeftDrawerBar>
    </div>
  )
};
