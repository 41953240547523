import styled, { css } from 'styled-components/macro';
import { colorGrey, colorWhite, colorRed } from '../../../../utils/css/colors';
import { mobile } from '../../../../utils/css/media';
import { bodyFont } from '../../../../utils/css/fonts';

export const CheckBoxContainer = styled.div<{ haveLabel: boolean }>`
  position: relative;
  margin-inline-start: ${({ haveLabel }) => (haveLabel ? '1rem' : 'unset')};
  display: flex;
  align-items: center;
  margin-inline-end: 12px;
  justify-content: ${({ haveLabel }) => (haveLabel ? 'unset' : 'center')};
  margin:0 !important;
`;

export const CheckBoxLabel = styled.label<{ error?: boolean }>`
  height: 18px;
  width: 18px;
  cursor: pointer;
  position: absolute;
  border-radius: 6px;
  border: 1px solid;
  border-color: ${({ error }) => (error ? colorRed : colorGrey)};
  background-color: ${colorWhite};

  &:after {
    content: "";
    width: 10px;
    height: 6px;
    position: absolute;
    top: 4px;
    left: 3px;
    border: 2px solid ${colorGrey};
    border-top: none;
    border-right: none;
    opacity: 0;
    transform: rotate(-45deg);
  }

  &:hover::after {
    opacity: 0.5;
  }

  ${mobile(css`
    &:hover::after {
      opacity: 0;
    }
  `)}
`;

export const CheckBoxInput = styled.input`
  visibility: hidden;

  &:checked + label:after {
    opacity: 1;
  }
`;

export const Label = styled.div`
  margin: 0 13px;
  
`;
