import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// actions
import { createGuestSalon, saveUnassignedGuestSelectedSalon } from "../../../../../store/actions/guest/guest.action";
// components
import ButtonSmall from "../../../../common/ButtonSmall";
// utils
import { ButtonCenterContainer } from "../../../../Admin/SalonTeaming/sidebarSalonTeaming/SelectedUserDetails/SelectedUserDetailsStyles";
import { Error } from "../../../../../utils/css/typography";
import { ISelectedSalonContainerProps } from "../../../../../interfaces/guests";
import ModalContext from "../ModalContext";
import useIsMobile from "../../../../../utils/usIsMobile";
import history from "../../../../../history";
import { routes } from "../../../../../utils/constants/routes";

const SelectedSalonContainer: React.FC<ISelectedSalonContainerProps> = ({
  salonDetails,
  distance,
  guestsNumber,
  setOpenSuccessPopup,
  setIsOpen,
  isNoAvailableSpaces,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const { token, error, guest_registration_status } = useSelector(
    ({
      // @ts-ignore
      authReducer: { token, guest, guest_registration_status },
      // @ts-ignore
      guestsReducer: { error },
    }) => ({
      token,
      error,
      guest,
      guest_registration_status,
    })
  );

  const { showModal } = React.useContext(ModalContext);

  const registerSalonAction = useCallback(
    (cb) => {
      return dispatch(
        createGuestSalon(
          { host_salon: salonDetails.id, guests_number: guestsNumber },
          cb
        )
      );
    },
    [dispatch, salonDetails.id, guestsNumber]
  );

  const handleRegisterSalon = useCallback(() => {
    // if user is registered
    setDisableSubmit(true);
    if (token !== null) {
      registerSalonAction(() => {
        setDisableSubmit(false);
        setIsOpen(false);
        setOpenSuccessPopup(true);
      });
    } else {
      dispatch(saveUnassignedGuestSelectedSalon(salonDetails, guestsNumber))
      setDisableSubmit(false);
      history.push(routes.register.registerGuest);
    }
  }, [registerSalonAction, showModal, token, guest_registration_status]);

  return (
    <>
      <ButtonCenterContainer>
        <ButtonSmall
          width={isMobile ? "150px" : "204px"}
          height={"56px"}
          colorType={"#ffda4c"}
          borderProps={"none"}
          colorProps={"black"}
          fontSize={"18px"}
          disabled={disableSubmit || isNoAvailableSpaces}
          onClick={() => {
            handleRegisterSalon();
          }}
        >
          {t("guests.salonSearchMap.submitButton")}
        </ButtonSmall>
      </ButtonCenterContainer>
      <Error error={error}>{t(error)}</Error>
    </>
  );
};

export default SelectedSalonContainer;
