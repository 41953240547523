import React from "react";
import i18next from "i18next"
import { getWitnessType } from "../../../../common/getString"
import { useTranslation } from "react-i18next";

const WitnessDetailsAndComments = ({
    witness_full_name,
    host_name,
    witness_type,
    comments_for_guests
  }: any) => {
    const { t } = useTranslation();
    return (
      <>
      {
        witness_full_name && (
          <>
            <p style={{marginBottom: '10px'}}>
            {i18next.t('guests.salonSearchMap.witnessAndSalonDetails', {
                witnessName: witness_full_name,
                hostName: host_name,
                witnessType:t(getWitnessType(witness_type))
            })}
            </p>
          </>
        )
      }
      {
        comments_for_guests && <span>{ comments_for_guests }</span>
      }
    </>
    )
}
export default WitnessDetailsAndComments;