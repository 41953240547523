import React from 'react';
import styled, { css } from 'styled-components';
import {
  colorMain,
  colorWhite,
  buttonShadow,
  colorDarkGrey,
  colorYellow2,
  colorYellowHover,
} from "../../utils/css/colors";
import { body14, button, buttonOutlined } from "../../utils/css/typography";
import { bodyFont } from "../../utils/css/fonts";
import { mobile } from "../../utils/css/media";

interface IProps {
  colorType?: string;
  isBold?: boolean;
  disabled?: boolean;
  cursor?: string;
  outlined?: boolean;
  applyShadow?: boolean;
  fontSize?: number | string;
  type?: string;
  left?: string;
  right?: string;
  width?: number | string;
  height?: number | string;
  bottom?: number | string;
  top?: number | string;
  padding?: string;
  isMargin?: string;
  borderProps?: string;
  textDecoration?: string;
  colorProps?: string;
  form?: string;
  isFontBold?: boolean;
  position?: string;
  textAlign?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  display?: string;
  marginInlineStart?: string;
}

const ButtonSmall: React.FC<IProps> = ({ children, ...props }) => {
  return <S.Button {...props}>{children}</S.Button>;
};

export const RegisterButton = styled(ButtonSmall)<{
  padding?: string;
  margin?: string;
  maxWidth?: string;
}>`
letter-spacing:0.015em;
  padding: ${({ padding }) => (padding ? padding : "1.4rem")};
  margin: ${({ margin }) => margin && margin};
  background-color: ${colorYellow2};
  color: ${colorDarkGrey};
  width: 300px;
  height: 4.8rem;
  ${body14};
  font-family: ${bodyFont};
  width: 100%;
  font-size: 18px;

  :hover {
    background: ${colorYellowHover};
  }

  ${mobile(css`
    margin: 0;
    width: 100%;
  `)};
`;

const S: any = {};
S.Button = styled.button<IProps>`
  display: ${(props) => props.display};
  background: ${(props) => props.colorType};
  color: ${({ isBold, outlined }) =>
    isBold || outlined ? colorMain : colorWhite};
  min-width: 9rem;
  border-radius: 0;
  min-height: 54px;
  top:${(props) => props.top};
  right: ${(props) => props.right};
  margin-inline-start: ${({ marginInlineStart }) =>  marginInlineStart && marginInlineStart};
  text-align: ${({textAlign}) => textAlign};
  cursor: ${({ cursor }) => cursor};
  left: ${({ left }) => left};
  text-decoration: ${({ textDecoration }) => textDecoration};
  white-space: nowrap;
  font-size: ${({ fontSize }) => fontSize && fontSize};
  width: ${({ width }) => `${width ? width : 'unset'}${typeof width === 'number'? 'px':''}`};
  position: ${(props) => props.position};
  height: ${({ height }) => height && height};
  bottom:  ${({ bottom }) => bottom && bottom};
  ${button};
  ${({ outlined }) => outlined && buttonOutlined};
  box-shadow: ${({ applyShadow }) => applyShadow && buttonShadow};
  padding: ${({ padding }) => (padding ? padding : '0.6rem 1.4rem')};
  margin: ${(props) => (props.isMargin ? props.isMargin : '')};
  border: ${(props) => props.borderProps};
  color: ${(props) => props.colorProps};
  font-family: ${bodyFont};
  font-weight: ${({ isFontBold }) => (isFontBold ? 'bold' : 'unset')};
  transition: all 0.3s ease-out;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: colorWhite;
  }
  ${mobile(css`
    margin: 0.5rem;
    min-width: 2rem;
  `)};
`;

export default ButtonSmall;
