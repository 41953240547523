import history from "../../../history";
import { routes } from "../../../utils/constants/routes";
import { errorActionTypes } from "./error.types";

export const redirectToErrorPage = (errorOrigin?: string) => {
  history.push(routes.errorPage.error, errorOrigin);
};

export const redirectToLoginPage = () => {
  history.push("/");
};

export const handleErrorFromServer = (errorData) => ({
  type: errorActionTypes.HANDLE_ERROR_FROM_SERVER,
  payload: errorData,
});
