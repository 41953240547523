import { RootState } from "../reducers";

export const hostsSelector = (state: RootState) => state.hostsReducer;

export const hostProfileSelector = (state: RootState) =>
  hostsSelector(state).hostProfile;
export const hostSalonsListSelector = (state: RootState) =>
  hostsSelector(state).hostSalonsList;
export const hostSalonsSelector = (state: RootState) =>
  hostsSelector(state).hostSalons;
export const hostIdSelector = (state: RootState) => hostsSelector(state).hostId;
export const hostsListSelector = (state: RootState) =>
  hostsSelector(state).hostsList;
export const hostsFullListSelector = (state: RootState) =>
  hostsSelector(state).hostsFullList;
export const hostsCountAllPagesSelector = (state: RootState) =>
  hostsSelector(state).hostsCountAllPages;
export const totalHostsAmountNoFiltersSelector = (state: RootState) =>
  hostsSelector(state).totalHostsAmountNoFilters;
export const nextFiltersSelector = (state: RootState) =>
  hostsSelector(state).nextFilters;
export const previousFiltersSelector = (state: RootState) =>
  hostsSelector(state).previousFilters;
export const isGetHostsListLoadingSelector = (state: RootState) =>
  hostsSelector(state).isGetHostsListLoading;
export const hostRegistrationDetailsSelector = (state: RootState) =>
  hostsSelector(state).hostRegistrationDetails;
