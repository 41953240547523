import { css } from "styled-components/macro";
import { colorGrey } from "./colors";
import scrollLine from "../../assets/icons/scrollLine.svg";

export const ScrollBarStyles = css`
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track {
    width: 8px;
    border: none;
    background: transparent;
  }

  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-track-piece,
  ::-webkit-scrollbar-corner,
  ::-webkit-resizer {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${colorGrey};
  }

  ::-webkit-scrollbar-track {
    background-image: url(${scrollLine});
    background-repeat: repeat-y;
    background-size: contain;
    background-position: center;
  }
`;
