import { useDispatch } from "react-redux";
import history from "../../history";
import { logout } from "../../store/actions/auth/auth.action";
import { resetHostId } from "../../store/actions/hosts/hosts.action";
import { clearGuestId } from "../../store/actions/guest/guest.action";

const useLogout = ({ navigateUser = true } = {}) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetHostId());
    dispatch(clearGuestId());
    if (navigateUser) history.push("/");
  };
  return handleLogout;
};

export default useLogout;
