const localStorageAuthKey = "persist:auth";
const localStorageRememberMeKey = "rememberMe";

const unauthorized_status = 401;
const forbidden_status = 403;
const blocked_from_outside_israel_status = 450;

export {
  localStorageAuthKey,
  localStorageRememberMeKey,
  unauthorized_status,
  forbidden_status,
  blocked_from_outside_israel_status,
};
