import { IHostProfile } from "../../../interfaces/hosts";
import { hostsActionTypes } from "./hosts.types";

//Host Profile
export const getHostProfile = (hostId: string) => ({
  type: hostsActionTypes.GET_HOST_PROFILE,
  payload: hostId,
});

export const updateHostProfile = (hostId: any, data: any, changeFromHost = false) => {
  return {
    type: hostsActionTypes.UPDATE_HOST_PROFILE,
    payload: {
      hostId,
      hostUpdateData: data,
      changeFromHost
    },
  };
};

// Create host
export const createHost = (data) => ({
  type: hostsActionTypes.CREATE_HOST,
  payload: data,
});

export const createHostSuccess = (data) => ({
  type: hostsActionTypes.CREATE_HOST_SUCCESS,
  payload: data,
});

export const createHostFailure = (error) => ({
  type: hostsActionTypes.CREATE_HOST_FAILURE,
  payload: error,
});

//Host salons
export const getHostSalonsList = (hostId: string) => ({
  type: hostsActionTypes.GET_HOST_SALONS_LIST,
  payload: hostId,
});

export const createHostSalonForExistingUser = (data, id) => ({
  type: hostsActionTypes.CREATE_HOST_SALON_FOR_EXISTING_USER,
  payload: { data, id },
});

// update host salons
export const updateHostSalons = (hostSalonsId, hostId,  data, step) => ({
  type: hostsActionTypes.UPDATE_HOST_SALON,
  payload: { data, hostSalonsId, step },
});


export const getHostSalons = (hostId: string) => ({
  type: hostsActionTypes.GET_HOST_SALONS,
  payload: hostId,
});

export const getHostSalonsSuccess = (data) => ({
  type: hostsActionTypes.GET_HOST_SALONS_SUCCESS,
  payload: data,
});

export const resetHostId = () => ({
  type: hostsActionTypes.RESET_HOST_ID,
});

// Clear host salon list
export const clearHostSalonList = () => ({
  type: hostsActionTypes.CLEAR_HOST_SALON_LIST,
});

//Delete host salon

export const deleteHostSalonById = (
  hostSalonId: any,
  salonYear: any
) => ({
  type: hostsActionTypes.DELETE_HOST_SALON_BY_ID,
  payload: { hostSalonId, salonYear },
});

// Delete host salon comment
export const deleteHostSalonComment = (
  hostSalonId: number | string,
  commentId: number
) => ({
  type: hostsActionTypes.DELETE_HOST_SALON_COMMENT,
  payload: { hostSalonId, commentId },
});

//Get host list

export const getHostsList = (params) => {
  return {
    type: hostsActionTypes.GET_ALL_HOSTS,
    payload: params,
  };
};

export const getHostsListSucsses = (data) => {
  return {
    type: hostsActionTypes.GET_ALL_HOSTS_SUCCESS,
    payload: data,
  };
};

export const getAllHostsList = (paramas) => {
  return {
    type: hostsActionTypes.GET_ALL_HOSTS_EXPORT,
    payload: paramas,
  };
};

export const getAllHostsListSuccess = (data) => {
  return {
    type: hostsActionTypes.GET_ALL_HOSTS_EXPORT_SUCCSSES,
    payload: data,
  };
};

export const deleteHostProfile = (hostId) => ({
  type: hostsActionTypes.DELETE_HOST_PROFILE,
  payload: hostId,
});


export const saveHostPersonalDetailsEdit = (data: Partial<IHostProfile>) => ({
  type: hostsActionTypes.SAVE_HOST_PERSONAL_DETAILS_EDIT,
  payload: data,
});

export const clearHostPersonalDetailsEdit = () => ({
  type: hostsActionTypes.CLEAR_HOST_PERSONAL_DETAILS_EDIT,
});
