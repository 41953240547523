import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as errorSelector from "../store/selectors/errorSelectors";
import Loader from "./common/Loader";
import { routes } from "../utils/constants/routes";
import PrivateRoute from "./PrivateRoute";
import { getAppData } from "../store/actions/admin/admin.action";
import Layout from "./Auth/Layout";
import ErrorHandler from "./common/ErrorHandler";
import { getOriginDetails } from "../store/actions/auth/auth.action";
import { GoogleMapScript } from "./common/GoogleMap";

const Login = lazy(() => import("./Auth/Login"));
const PrivacyPolicyHeb = lazy(() =>
  import("./Auth/Register/RegisterForm/PrivacyPolicy/Heb")
);
const ForgotPasswordRestoreMethod = lazy(() =>
  import("./Auth/ForgotPassword/ForgotPasswordRestoreMethod")
);
const ForgotPasswordConfirm = lazy(() =>
  import("./Auth/ForgotPassword/ForgotPasswordConfirm")
);
const ForgotPasswordSuccess = lazy(() =>
  import("./Auth/ForgotPassword/ForgotPasswordSuccess")
);
const Register = lazy(() => import("./Auth/Register"));
const PolicyTermsHeb = lazy(() =>
  import("./Auth/Register/RegisterForm/PolicyTerms/Heb")
);
const PolicyTermsEng = lazy(() =>
  import("./Auth/Register/RegisterForm/PolicyTerms/Eng")
);
const RegisterHost = lazy(() =>
  import("./Auth/Register/RegisterHost/RegisterHostStep")
);
const RegisterGuest = lazy(() => import("./Auth/Register/RegisterGuest/index"));
const registerGuestOnboarding = lazy(() =>
  import("./Auth/Register/RegisterGuest/Onboarding/index")
);
const registerHostOnboarding = lazy(() =>
  import("./Auth/Register/RegisterHost/Onboarding/index")
);
const RegisterGuestResult = lazy(() =>
  import("./Auth/Register/RegisterGuest/Result")
);
const CreateSalon = lazy(() =>
  import("./Auth/Register/RegisterHost/CreateSalon/index")
);
const CreateSalonResult = lazy(() =>
  import("./Auth/Register/RegisterHost/CreateSalon/Result")
);
const Admin = lazy(() => import("./Admin"));
const Site = lazy(() => import("./Site"));
const ErrorPage = lazy(() => import("./Errors/ErrorPage"));
const LoginWithSms = lazy(() =>
  import("./Auth/Login/LoginWithSms/LoginWithSms")
);
const CodeVerification = lazy(() =>
  import("./Auth/Login/LoginWithSms/CodeVerification/CodeVerification")
);
const WantToTell = lazy(() =>
  import("./Auth/Register/RegisterWitness/WantToTell/WnatToTell")
);
const WitnessRegistrationSteps = lazy(() =>
  import(
    "./Auth/Register/RegisterWitness/WitnessRegistrationSteps/WitnessRegistrationSteps"
  )
);
const AccessibilityPage = lazy(() =>
    import("./Accessibility")
  );

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    dispatch(getAppData());
    dispatch(getOriginDetails());
  }, [dispatch]);

  const errorKeys = useSelector(errorSelector.errorKeysSelector);

  return (
    <Suspense fallback={<Loader />}>
      <GoogleMapScript/>
      <Switch>
        <Route path="/" exact>
          <Layout showHomePageSection={false}>
            <Login />
          </Layout>
        </Route>
        <Route
          path={routes.forgotPassword.restoreMethod}
          exact
          component={ForgotPasswordRestoreMethod}
        />
        <Route
          path={routes.forgotPassword.success}
          exact
          component={ForgotPasswordSuccess}
        />
        <Route
          path={routes.forgotPassword.password}
          exact
          component={ForgotPasswordConfirm}
        />
        <Route path={routes.register.root} exact component={Register} />
        <Route
          path={routes.register.registerHost}
          exact
          component={RegisterHost}
        />
        <Route
          path={routes.register.registerGuest}
          exact
          component={RegisterGuest}
        />
        <Route
          path={routes.register.registerGuestOnboarding}
          exact
          component={registerGuestOnboarding}
        />
        <Route
          path={routes.register.registerHostOnboarding}
          exact
          component={registerHostOnboarding}
        />
        <Route path={routes.register.wantToTell} exact component={WantToTell} />
        <Route
          path={routes.register.witnessRegistration}
          component={WitnessRegistrationSteps}
        />
        <Route
          path={routes.register.policyTermsHeb}
          exact
          component={PolicyTermsHeb}
        />
        <Route
          path={routes.register.policyTermsEng}
          exact
          component={PolicyTermsEng}
        />
        <Route path={routes.register.createSalon} component={CreateSalon} />
        <Route
          path={routes.loginWithSms.getCodeViaSms}
          component={LoginWithSms}
        />
        <Route
          path={routes.loginWithSms.codeVerification}
          component={CodeVerification}
        />
        <Route
          path={routes.register.createSalonResult}
          component={CreateSalonResult}
        />
        <Route
          path={routes.register.registerGuestResult}
          exact
          component={RegisterGuestResult}
        />
        <Route path="/site" component={Site} />
        <PrivateRoute path="/admin" component={Admin} />
        <Route path="/privacy_policy_heb" component={PrivacyPolicyHeb} />
        <Route path="/salon" component={PrivacyPolicyHeb} />
        <Route path="/myProfile" component={PrivacyPolicyHeb} />
        <Route
          path={routes.accessibilityPage.root}
          exact
          component={AccessibilityPage}
        />
        <Route component={ErrorPage} />
      </Switch>
      <ErrorHandler errorHandlerKeys={errorKeys} />
    </Suspense>
  );
};

export default Routes;
