import { salonsActionTypes } from "../actions/admin/salons.types";
import {
  ISalonState,
  IDetailedWitnessForTeaming,
  TableTeamingParams,
  EdisplayInfomationType,
} from "../../interfaces/admin";

const initialState: ISalonState = {
  salonsList: [],
  salonsFullList: [],
  salonsCountAllPages: 0,
  totalSalonsAmountNoFilters: 0,
  isGetSalonsListLoading: false,
  nextFilters: null,
  previousFilters: null,
  witnessesListForTeaming: [],
  hostSalonsListForTeaming: [],
  isGetTeamingDataLoading: false,
  selectedForTeaming: {} as IDetailedWitnessForTeaming,
  relevantOptionsForTeamingList: [],
  teamingTableParmas: {} as TableTeamingParams,
  arePaginationOptionsLoading: false,
  isNextPaginationPageAvailable: false,
  nextTeamingOptionsPaginationPage: 1,
  currentSalonDetails: null,
  error: null,
  isIOSModalOpen: false,
};

const salonsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case salonsActionTypes.GET_SALONS_LIST_LOADING: {
      return { ...state, isGetSalonsListLoading: true };
    }
    case salonsActionTypes.ADMIN_DELETE_HOST_SALON_BY_ID: {
      return {
        ...state,
        salonsList: state.salonsList.filter(
          (salon) => salon.id !== action.payload
        ),
        salonsCountAllPages:
          state.salonsCountAllPages -
          state.salonsList.filter((salon) => salon.id === action.payload)
            .length,
        totalSalonsAmountNoFilters:
          state.totalSalonsAmountNoFilters -
          state.salonsList.filter((salon) => salon.id === action.payload)
            .length,
      };
    }
    case salonsActionTypes.GET_SALONS_SUCCESS: {
      const {
        salonsList,
        salonsCountAllPages,
        totalSalonsAmountNoFilters,
        nextFilters,
        previousFilters,
      } = action.payload;
      return {
        ...state,
        salonsList,
        salonsCountAllPages,
        totalSalonsAmountNoFilters,
        nextFilters,
        previousFilters,
        isGetSalonsListLoading: false,
      };
    }
    case salonsActionTypes.GET_SALONS_AND_WITNESSES_FOR_TEAMING_SUCCESS:
      const { witnessesListForTeaming, hostSalonsListForTeaming } =
        action.payload;
      return {
        ...state,
        isGetTeamingDataLoading: false,
        witnessesListForTeaming,
        hostSalonsListForTeaming,
      };
    case salonsActionTypes.GET_SALONS_AND_WITNESSES_FOR_TEAMING_WITH_PAGINATION_SUCCSSES:
      const {
        witnessesListForTeamingForTable,
        hostSalonsListForTeamingForTable,
        teamingTableParmas,
      } = action.payload;
      return {
        ...state,
        witnessesListForTeaming: witnessesListForTeamingForTable,
        hostSalonsListForTeaming: hostSalonsListForTeamingForTable,
        teamingTableParmas,
      };
    case salonsActionTypes.GET_SALONS_OR_WITNESSES_FOR_TEAMING_SUCCESS: {
      const { dataForTeaming, teamingSide } = action.payload;
      const { results, ...paginationProps } = dataForTeaming;
      const teamingTableParmas = {
        count: paginationProps.count,
        next: paginationProps.nextFilters,
        previous: paginationProps.previousFilters,
        total_no_filters: paginationProps.total_no_filters,
      };
      if (teamingSide === EdisplayInfomationType.witness) {
        return {
          ...state,
          witnessesListForTeaming: dataForTeaming.results,
          teamingTableParmas,
        };
      } else {
        return {
          ...state,
          hostSalonsListForTeaming: dataForTeaming.results,
          teamingTableParmas,
        };
      }
    }
    case salonsActionTypes.CLEAR_SALONS_AND_WITNESSES_FOR_TEAMING:
      return {
        ...state,
        witnessesListForTeaming: [],
        hostSalonsListForTeaming: [],
        teamingTableParmas: {},
      };
    case salonsActionTypes.GET_TEAMING_DATA_LOADING:
      return {
        ...state,
        isGetTeamingDataLoading: true,
        nextTeamingOptionsPaginationPage: 2,
      };
    case salonsActionTypes.SET_SELECTED_SALON:
      return {
          ...state,
          selectedSalonId: action.payload,
        };
    case salonsActionTypes.GET_DATA_FOR_TEAMING_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        selectedForTeaming: payload.selectedForTeaming,
        relevantOptionsForTeamingList: payload.relevantOptionsForTeamingList,
        nextTeamingOptionsPaginationPage: 2,
        isGetTeamingDataLoading: false,
        isNextPaginationPageAvailable: payload.isNextPaginationPageAvailable,
      };
    }
    case salonsActionTypes.INIT_DATA_FOR_TEAMING: {
      return {
        ...state,
        selectedForTeaming: {},
        relevantOptionsForTeamingList: [],
        isGetTeamingDataLoading: false,
        totalResultsAvailable: null,
        nextTeamingOptionsPaginationPage: 2,
        isNextPaginationPageAvailable: false,
      };
    }
    case salonsActionTypes.GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_INIT:
      return {
        ...state,
        arePaginationOptionsLoading: true,
      };
    case salonsActionTypes.GET_PAGINATION_SALON_AND_WITNESS_TEAMING_OPTIONS_SUCCESS:
      const { payload } = action;

      return {
        ...state,
        arePaginationOptionsLoading: false,
        relevantOptionsForTeamingList: [
          ...state.relevantOptionsForTeamingList,
          ...payload.relevantOptionsForTeamingList,
        ],
        nextTeamingOptionsPaginationPage: payload.nextPaginationPage,
      };
    case salonsActionTypes.GET_ALL_SALONS_LIST_SUCCSSES: {
      return {
        ...state,
        salonsFullList: action.payload,
      };
    }
    case salonsActionTypes.GET_HOST_SALON_BY_SALON_ID_SUCCESS: {
      return {
        ...state,
        currentSalonDetails: action.payload,
      };
    }
    case salonsActionTypes.GET_HOST_SALON_BY_SALON_ID_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case salonsActionTypes.IS_SALON_MODAL_OPEN: {
      return {
        ...state,
        isIOSModalOpen: action.payload,
      };
    }
    default:
      return state;
  }
};

export default salonsReducer;
