import { authActionTypes } from "./auth.types";
import { IUserUpdate, LoginFormValues } from "../../../interfaces/auth";
import { IHostRegistrationDetails } from "../../../interfaces/hosts";
declare const dataLayer;
export const login = (
  formValues: LoginFormValues | {},
  usingModal = null,
  modalCallback = null
) => ({
  type: authActionTypes.LOGIN_REQUEST,
  payload: { formValues, usingModal, modalCallback },
});

export const loginWithGoogle = (googleRes: any, role: string) => ({
  type: authActionTypes.GOOGLE_LOGIN_REQUEST,
  payload: { googleRes, role },
});

export const forgotPasswordLogin = (data, onPasswordChange) => ({
  type: authActionTypes.FORGOT_PASSWORD_LOGIN,
  payload: { data, onPasswordChange },
});

export const getOriginDetails = () => ({
  type: authActionTypes.ORIGIN_REQUEST,
});

export const getOriginDetailsSuccess = (data) => ({
  type: authActionTypes.ORIGIN_REQUEST_SUCCESS,
  payload: data,
});

export const silentLogin = (data) => ({
  type: authActionTypes.SILENT_LOGIN,
  payload: data,
});

export const loginSuccess = (data) => ({
  type: authActionTypes.LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (errorKeys: string[]) => ({
  type: authActionTypes.LOGIN_FAILURE,
  payload: errorKeys,
});

export const logout = () => ({
  type: authActionTypes.LOGOUT,
});

// Update user

export const updateUserRequest = (data: Partial<IUserUpdate>, id: string) => ({
  type: authActionTypes.UPDATE_USER_REQUEST,
  request: {
    url: `/api/user_data/${id}/`,
    method: `PATCH`,
    data,
  },
});

export const updateUserSuccess = (data: any) => ({
  type: authActionTypes.UPDATE_USER_SUCCESS,
  payload: data,
});

export const updateUserFailure = (error: string) => ({
  type: authActionTypes.UPDATE_USER_FAILURE,
  payload: error,
});

// Confirm Privacy Policy

export const confirmPolicyRequest = (confirm_policy: boolean, id: string) => ({
  type: authActionTypes.CONFIRM_POLICY_REQUEST,
  request: {
    url: `/api/user_data/${id}/`,
    method: `PATCH`,
    data: {
      confirm_policy: confirm_policy,
    },
  },
});

export const confirmPolicySuccess = (payload: { confirm_policy: any }) => ({
  type: authActionTypes.CONFIRM_POLICY_SUCCESS,
  payload,
});

export const confirmPolicyFailure = (payload: { error: string }) => ({
  type: authActionTypes.CONFIRM_POLICY_FAILURE,
  payload,
});

// REGISTER USER

export const registerUser = (
  formValues,
  onError,
  usingModal,
  modalCallback
) => ({
  type: authActionTypes.REGISTER_USER,
  payload: { formValues, onError, usingModal, modalCallback },
});

export const registerUserSuccess = (data) => {
  if (data.user.role === "host") {
    dataLayer.push({
      Category: "App_Registration",
      Action: "Click",
      Label: "Want_to_host",
      event: "auto_event",
    });
  } else {
    dataLayer.push({
      Category: "App_Registration",
      Action: "Click",
      Label: "Want_to_be_hosted",
      event: "auto_event",
    });
  }

  return {
    type: authActionTypes.REGISTER_USER_SUCCESS,
    payload: data,
  };
};

export const registerUserFailure = (
  error: { parameter: string; message: string }[]
) => ({
  type: authActionTypes.REGISTER_USER_FAILURE,
  payload: error,
});

// REGISTER GUEST

export const registerGuest = (
  formValues,
  step,
  guestId,
  usingModal,
  modalCallback
) => ({
  type: authActionTypes.REGISTER_GUEST,
  payload: { formValues, step, guestId, usingModal, modalCallback },
});

export const registerGuestSuccess = (data) => ({
  type: authActionTypes.REGISTER_GUEST_SUCCESS,
  payload: data,
});

export const registerGuestFailure = (
  error: { parameter: string; message: string }[]
) => ({
  type: authActionTypes.REGISTER_GUEST_FAILURE,
  payload: error,
});

export const registerHostSuccess = (data) => ({
  type: authActionTypes.REGISTER_HOST_SUCCESS,
  payload: data,
});

// Get code to login with SMS

export const sendLoginSmsCodeRequest = ({ phone_number }) => ({
  type: authActionTypes.SEND_LOGIN_SMS_CODE_REQUEST,
  request: {
    url: `/api/sms_authentication/auth/`,
    method: `POST`,
    data: {
      phone_number,
    },
  },
});

export const sendLoginSmsCodeSuccess = (phone: string) => ({
  type: authActionTypes.SEND_LOGIN_SMS_CODE_SUCCESS,
  payload: phone,
});

export const sendLoginSmsCodeFailure = (payload: string[]) => ({
  type: authActionTypes.SEND_LOGIN_SMS_CODE_FAILURE,
  payload,
});

// Code verification and logging in

export const loginCodeVerification = (
  phone_number: string,
  code: string,
  isResetPassword: boolean
) => ({
  type: authActionTypes.LOGIN_CODE_VERIFICATION_REQUEST,
  request: {
    url: `/api/sms_authentication/auth_with_code/`,
    method: `POST`,
    data: {
      phone_number,
      code,
    },
  },
  config: {
    isResetPassword,
  },
});

export const loginCodeVerificationSuccess = (data) => ({
  type: authActionTypes.LOGIN_CODE_VERIFICATION_SUCCESS,
  payload: data,
});

export const loginCodeVerificationFailure = (error) => ({
  type: authActionTypes.LOGIN_CODE_VERIFICATION_FAILURE,
  payload: error,
});

export const openChangeRolesModal = (isShowModal: boolean) => ({
  type: authActionTypes.OPEN_CHANGE_ROLES_MODAL,
  payload: isShowModal,
});

export const closeChangeRolesModal = (isShowModal: boolean) => ({
  type: authActionTypes.CLOSE_CHANGE_ROLES_MODAL,
  payload: isShowModal,
});

export const changeHostToGuestRequest = (id: string, callback: Function) => ({
  type: authActionTypes.CHANGE_HOST_TO_GUEST_REQUEST,
  request: {
    url: `/api/hosts/${id}/change_host_to_guest/`,
    method: `GET`,
    callback: callback,
  },
});

export const changeHostToGuestSuccess = (data: object) => ({
  type: authActionTypes.CHANGE_HOST_TO_GUEST_SUCCESS,
  payload: data,
});

export const changeHostToGuestFailure = (error: string) => ({
  type: authActionTypes.CHANGE_HOST_TO_GUEST_FAILURE,
  payload: error,
});

export const changeGuestToHostRequest = (id: number, callback?: Function) => ({
  type: authActionTypes.CHANGE_GUEST_TO_HOST_REQUEST,
  request: {
    url: `/api/guests/${id}/change_guest_to_host/`,
    method: `GET`,
    callback,
  },
});

export const changeGuestToHostSuccess = (data) => ({
  type: authActionTypes.CHANGE_GUEST_TO_HOST_SUCCESS,
  payload: data,
});

export const changeGuestToHostFailure = (error: string) => ({
  type: authActionTypes.CHANGE_GUEST_TO_HOST_FAILURE,
  payload: {
    error,
  },
});

export const hostRegistrationDetails = ({
  ...formValues
}: Partial<IHostRegistrationDetails>) => ({
  type: authActionTypes.SAVE_HOST_REGISTRATION_DETAILS,
  payload: formValues,
});

export const clearHostRegistrationDetails = () => ({
  type: authActionTypes.CLEAR_HOST_REGISTRATION_DETAILS,
});
