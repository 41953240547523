export const colorBlack = "#000";
export const colorMain = "rgba(0, 0, 0, 0.87)";
export const colorLightYellow = "#F2C94C";
export const colorYellow = "#FFCB05";
export const colorYellowHover = "#E4B604";
export const colorYellow2 = "#F5D367";
export const colorGold = "#E3B400";
export const colorDarkGold = "#b38e03";
export const colorDarkGrey = "#333333";
export const colorDarkGreyHover = "#5A5A5A";
export const colorGrey = "#4F4F4F";
export const colorBoldGray = "#5B5B5B";
export const colorLightGrey = "#E0E0E0";
export const colorLighterGrey = "#959595";
export const colorPaleGrey = "#F2F2F2";
export const colorShadedGrey = "#AAAAAA";
export const colorWhite = "#FFFFFF";
export const colorBorder = "rgba(0, 0, 0, 0.3);";
export const colorBackground = "#FFFFFF";
export const colorPink = "#DB838E";
export const colorPeach = "#DB838E";
export const colorRed = "#EE4747";
export const colorRedHover = "#D54040";
export const colorLightRed = "#FF6262";
export const colorBlue = "#1154AD";
export const colorGreen = "#71FCAB";
export const colorDarkBlue = "#3a5a99";
export const colorTransparentShareSalon = "transparent";
export const colorTransparentShareSalonHover = "#37321E";
export const colorTransparentCloseSalon = "transparent";
export const colorTransparentCloseSalonnHover = "#372727";
export const colorFontBlack = "#222020";
export const colorLightGrey2 = "#E1E1E1";
export const colorLightBrown = "#EDEADC";

export const colorDarkGreen = "#27AE60";
export const colorDarkRed = "#EB5757";

export const inputOpacity = 0.54;
export const inputDisabledOpacity = 0.7;
export const inputBorderColor = "#BC9D24";
export const selectBorderColor = "#BBBBBB";
export const inputActiveBorderColor = "#7F6B21";
export const inputFocusedShadow = "0px 0px 5px rgba(238, 71, 153, 0.8);";
export const listItemHover = "rgba(238, 71, 153, 0.4)";
export const selectListShadow = "0px 4px 4px rgba(0, 0, 0, 0.25);";
export const formSelectShadow =
  "0 0px 0px rgba(107,85,0,0.87) inset, 0 0 8px rgba(107,85,0,0.87);";
export const buttonShadow =
  "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);";

export const colorMainLightGrey = "#E6E6E6";
export const colorBackgroundLightGrey = "#FAFAFA";
