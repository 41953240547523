export const closeOnBlur = (ref, setDisplay) => {
  // Close dropdown if clicked on outside of element
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setDisplay(false);
    }
  }

  // Bind the event listener
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener("mousedown", handleClickOutside);
  };
};
