import styled, { css } from "styled-components/macro";
import { colorBackground } from "./colors";
import { mobile } from "./media";
import { ScrollBarStyles } from "./scrollBar";
import { footerHeight, mobileHeaderHeight } from "../../utils/css/sizes";
import { isIOS } from "react-device-detect";

const Body = styled.div<{
  lng: string;
  isFullHeight?: boolean;
  isIOSModalOpen?: boolean;
}>`
  direction: ${({ lng }) => (lng ? (lng === "en" ? "ltr" : "rtl") : "rtl")};
  background-color: ${colorBackground};
  height: ${({ isFullHeight }) =>
    isFullHeight ? "100%" : `calc(100% - ${footerHeight}px)`};
  ${mobile(css<{ isIOSModalOpen: boolean }>`
    overflow-y: auto;
    height: 100%;
    position: ${({ isIOSModalOpen }) =>
      isIOSModalOpen && isIOS ? "fixed" : "relative"};
    margin-top: ${({ isIOSModalOpen }) => (isIOSModalOpen && isIOS ? "70px" : `${mobileHeaderHeight}px`)};
    right: ${({ isIOSModalOpen }) => (isIOSModalOpen && isIOS ? "0" : "")};
    left: ${({ isIOSModalOpen }) => (isIOSModalOpen && isIOS ? "0" : "")};
  `)};
`;

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const SectionPaddingRight = css`
  padding-right: 138px;
`;
const SectionPaddingLeft = css`
  padding-left: 138px;
`;

const Content = styled.section`
  flex-grow: 1;
  overflow-y: auto;
  ${ScrollBarStyles}
  ${mobile(css`
    overflow-y: unset;
  `)};
`;

export { Body, Container, Content, SectionPaddingRight, SectionPaddingLeft };
