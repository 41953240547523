import styled, { css } from "styled-components";
import React from "react";
import { colorWhite, colorFontBlack } from "../../../../../utils/css/colors";
import { IMarkerInformation } from "../../../../../interfaces/common";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { lightFont, bodyFont, boldFont } from "../../../../../utils/css/fonts";
import { mobile } from "../../../../../utils/css/media";
const Wrapper = styled.div`
  cursor: auto;
  position: absolute;
  white-space: pre;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  top: 20px;
  min-width: 297px;
  background-color: ${colorWhite};
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 1;
  font-family: ${lightFont};
  color: ${colorFontBlack};
  ${mobile(css`
    margin-right: -150px;
    white-space: pre-wrap;
  `)}
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
  align-items: flex-end;
  font-family: ${bodyFont};
  font-size: 18px;
  ${mobile(css`
    flex-direction: column;
    align-items: center;
  `)}
`;

const Headline = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  margin-inline-end: 10px;
  margin-bottom: 2px;
  font-family: ${boldFont};
  text-overflow: ellipsis;
  ${mobile(css`
    margin-bottom: 15px;
    text-align: center;
  `)}

`;

const DateWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const DateText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${colorFontBlack};
  margin: 0 6px;
`;

const AddressText = styled.p`
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 11px;
`;

const AddressWrapper = styled.div`
  display: flex;
  p {
    margin-inline-end: 4px;
  }
  ${mobile(css`
    flex-wrap: wrap;
    justify-content: center;
  `)}
`;
const DetailsWrapper = styled.div`
font-size: 18px;
line-height: 20px;
display: flex;
text-align: start;
text-decoration-line: underline;
color: #000000;
font-family: ${bodyFont};
text-underline-offset: 3px;
cursor: pointer;
  ${mobile(css`
    justify-content: center;
  `)}
`;
export const MarkerInformation: React.FC<any> = (
  data
) => {
  const { t } = useTranslation();

  const salonDetails={
    name: data.salon_name,
    event_time: data.event_time,
    address:data.address,
    city: data.city_name,
    floor: data.floor,
    apartment: data.apartment_number,
    id: data.salon_id
  }
  
 
  return (
    <Wrapper
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
      }}
    >
      <HeaderWrapper>
        <Headline>
          {" "}
          <h1>
            <span>{`${t('guests.salonSearchMap.salonHeader')}`} {salonDetails.name}</span> 
          </h1>
        </Headline>
        <DateWrapper>
          <DateText>{moment(salonDetails.event_time).format("DD.MM.YY")}</DateText>
          <Dot />
          <DateText>{moment(salonDetails.event_time).format("HH:mm:ss")}</DateText>
        </DateWrapper>
      </HeaderWrapper>
      <AddressWrapper>
      <AddressText>{salonDetails.city}, </AddressText>
        <AddressText>{salonDetails.address}</AddressText>
        <AddressText>,</AddressText>
        <AddressText>{salonDetails.floor && salonDetails.floor !==9999 ? `${t("formFields.floor")} ${salonDetails.floor}` : "" }</AddressText>
        <AddressText>{salonDetails.apartment && salonDetails.apartment !==9999 ? `${t("formFields.apartment")} ${salonDetails.apartment}` : "" }</AddressText>
      </AddressWrapper>

      <DetailsWrapper onClick={() => {
         data.setSideDrawerOpen(true);
      }}>
          {t("guests.salonSearchMap.moreDetails")}
      </DetailsWrapper>
    </Wrapper>
  );
};
