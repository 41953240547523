import { RootState } from "../reducers";

export const authSelector = (state: RootState) => state.authReducer;

export const tokenSelector = (state: RootState) => authSelector(state).token;
export const emailSelector = (state: RootState) => authSelector(state).email;
export const idSelector = (state: RootState) => authSelector(state).id;
export const firstNameSelector = (state: RootState) =>
  authSelector(state).first_name;
export const lastNameSelector = (state: RootState) =>
  authSelector(state).last_name;
export const roleSelector = (state: RootState) => authSelector(state).role;
export const isFromILSelector = (state: RootState) => authSelector(state).isFromIL;
export const hostSelector = (state: RootState) => authSelector(state).host;
export const guestSelector = (state: RootState) => authSelector(state).guest;
export const guestRegistrationStatusSelector = (state: RootState) =>
  authSelector(state).guest_registration_status;
export const errorKeysSelector = (state: RootState) =>
  authSelector(state).errorKeys;
export const isFirstTimeAdminUserSelector = (state: RootState) =>
  !authSelector(state).confirm_policy;
export const phoneNumberSelector = (state: RootState) =>
  authSelector(state).phoneNumber;
export const isShowModalSelector = (state: RootState) =>
  authSelector(state).isShowModal;
export const isChangeRoleThisYearSelector = (state: RootState) =>
  authSelector(state).isChangeRoleThisYear;
