import styled, {css} from "styled-components/macro";
import { boldFont } from "../../../utils/css/fonts";
import { mobile } from '../../../utils/css/media';

export const LanguageChoices  = styled.div`
  display: flex;
  gap: 8px;
  font-size: 18px;
  align-items: center;
  margin-inline-start: 0rem;
  margin-inline-end: 3rem;
  ${mobile(css`
    margin-left: 0;
 `)}
`;

export const Divider = styled.div`
  border-left: 1px solid;
  height: 18px;
`;

export const LanguageChoice = styled.div<{ isBold: boolean }>`
  cursor: pointer;
  font-family:  ${({ isBold }) => isBold  && boldFont };
`;