import { guestActionTypes } from "../actions/guest/guest.types";
import { salonSearchMapActionTypes } from "../actions/guest/salonSearchMap.types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { IGuestProfile, IGuestSalon } from "../../interfaces/guests";
import { authActionTypes } from "../actions/auth/auth.types";

const initialState = {
  languages: [],
  languageError: "",
  isAvailableSalonsLoading: false,
  availableSalonsList: [],
  availableSalonsError: "",
  guestProfile: {} as IGuestProfile,
  guestSalon: {} as IGuestSalon,
  guestId: null,
  error: "",
  errorKeys: [],
  guestsList: [],
  guestsFullList: [],
  digitalSalonsList: [],
  digitalSalonsCountAllPages: 0,
  totalDigitalSalonsAmountNoFilters: 0,
  isGetDigitalSalonsListLoading: false,
  nextDigitalSalonFilters: null,
  previousDigitalSalonFilters: null,
  guestCountAllPages: 0,
  totalGuestsAmountNoFilters: 0,
  nextFilters: null,
  previousFilters: null,
  isGetGuestsListLoading: false,
  guest_registration_status: null,
  guestPersonalDetailsEdit: [],
  unassignedGuestSalonDetails: {}
};

const guestsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case salonSearchMapActionTypes.GET_LANGUAGES_SUCCESS: {
      return { ...state, languages: action.payload, languageError: "" };
    }
    case guestActionTypes.GET_GUESTS_LIST_LOADING: {
      return { ...state, isGetGuestsListLoading: true };
    }
    case guestActionTypes.GET_ALL_GUESTS_SUCCESS: {
      const {
        guestsList,
        guestCountAllPages,
        totalGuestsAmountNoFilters,
        nextFilters,
        previousFilters,
      } = action.payload;
      return {
        ...state,
        guestsList,
        guestCountAllPages,
        totalGuestsAmountNoFilters,
        nextFilters,
        previousFilters,
        isGetGuestsListLoading: false,
      };
    }
    case guestActionTypes.GET_ALL_GUESTS_LIST_SUCCSSES: {
      return {
        ...state,
        guestsFullList: action.payload,
      };
    }
    case salonSearchMapActionTypes.GET_LANGUAGES_FAILURE: {
      return { ...state, languages: [], languageError: action.error };
    }
    case salonSearchMapActionTypes.GET_AVAILABLE_SALONS_LIST_INIT: {
      return {
        ...state,
        availableSalonsList: [],
        isAvailableSalonsLoading: true,
        availableSalonsError: "",
      };
    }
    case salonSearchMapActionTypes.GET_AVAILABLE_SALONS_LIST_SUCCESS: {
      return {
        ...state,
        availableSalonsList: action.payload.sort(a => a.distance).reverse(),
        isAvailableSalonsLoading: false,
        availableSalonsError: "",
      };
    }
    case salonSearchMapActionTypes.GET_AVAILABLE_SALONS_LIST_FAIL: {
      return {
        ...state,
        availableSalonsList: [],
        availableSalonsError: action.error,
        isAvailableSalonsLoading: false,
      };
    }
    case guestActionTypes.GET_GUEST_BY_ID_SUCCESS:
    case authActionTypes.CHANGE_HOST_TO_GUEST_SUCCESS: {
      const { id, phone } = action.payload;
      return {
        ...state,
        guestProfile: { ...action.payload, phone: phone ? phone : "" },
        guestId: id,
      };
    }
    case authActionTypes.CHANGE_HOST_TO_GUEST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case guestActionTypes.UPDATE_GUEST_PROFILE_SUCCESS: {
      return { ...state, guestProfile: action.payload };
    }
    case guestActionTypes.GET_GUEST_SALON_SUCCESS: {
      return { ...state, guestSalon: action.payload };
    }
    case guestActionTypes.DELETE_GUEST_SALON: {
      return { ...state, guestSalon: {} };
    }
    case guestActionTypes.UPDATE_GUEST_SALON_SUCCESS: {
      return { ...state, guestSalon: action.payload };
    }
    case guestActionTypes.CLEAR_GUEST_PROFILE: {
      return {
        ...state,
        guestProfile: {},
        guestSalon: {},
      };
    }
    case guestActionTypes.CLEAR_GUEST_ID: {
      return {
        ...state,
        guestId: null,
      };
    }
    case guestActionTypes.CREATE_GUEST_SALON_FAILURE: {
      return {
        ...state,
        error: action.payload,
        errorKeys: action.payload,
      };
    }
    case salonSearchMapActionTypes.GET_AVAILABLE_DIGITAL_SALONS_TABLE_SUCCESS: {
      const {
        digitalSalonsList,
        digitalSalonsCountAllPages,
        totalDigitalSalonsAmountNoFilters,
        nextFilters: nextDigitalSalonFilters,
        previousFilters: previousDigitalSalonFilters,
      } = action.payload;
      return {
        ...state,
        digitalSalonsList,
        digitalSalonsCountAllPages,
        totalDigitalSalonsAmountNoFilters,
        nextDigitalSalonFilters,
        previousDigitalSalonFilters,
        isGetDigitalSalonsListLoading: false,
      };
    }
    case guestActionTypes.SAVE_GUEST_PERSONAL_DETAILS_EDIT: {
      return {
        ...state,
        guestPersonalDetailsEdit: action.payload,
      };
    }
    case guestActionTypes.CLEAR_GUEST_PERSONAL_DETAILS_EDIT: {
      return {
        ...state,
        guestPersonalDetailsEdit: [],
      };
    }
    case guestActionTypes.SAVE_UNASSIGNED_GUEST_SELECTED_SALON: {
      return {
        ...state,
        unassignedGuestSalonDetails: action.payload
      }
    }
    default:
      return state;
  }
};

const persistConfig = {
  key: "guests",
  storage: storage,
  whitelist: ["guestId"],
};

export default persistReducer(persistConfig, guestsReducer);
