import { instance as axios } from "../middleware/auth.middleware";

const resetPasswordViaEmailURL = "/api/password_reset/";
const resetPasswordViaSmsURL = "/api/sms_authentication/change_password/";
const convertUserSelectedUrl = "/api/user_data/convert_user_selected/";

export const sendResetPasswordRequest = (email) => {
  return axios.post(resetPasswordViaEmailURL, email);
};

export const resetPasswordViaEmail = (data) => {
  return axios.post(`${resetPasswordViaEmailURL}confirm/`, data);
};

export const resetPasswordViaSMS = (data) => {
  return axios.post(resetPasswordViaSmsURL, data);
};

export const convertUserSelected = () => {
  return axios.post(convertUserSelectedUrl);
};
