import { authActionTypes } from '../actions/auth/auth.types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IAuthState } from '../../interfaces/auth';

const initialState: IAuthState = {
	token: null,
	id: '',
	email: null,
	first_name: '',
	last_name: '',
	role: '',
	host: null,
	guest: null,
	guest_registration_status: null,
	errorKeys: [],
	confirm_policy: false,
	phoneNumber: null,
	isShowModal: false,
	isChangeRoleThisYear: false,
	isFromIL: true
};

const authReducer = (state = initialState, action: any): Partial<IAuthState> => {
	switch (action.type) {
		case authActionTypes.LOGIN_SUCCESS:
		case authActionTypes.REGISTER_USER_SUCCESS:
		case authActionTypes.LOGIN_CODE_VERIFICATION_SUCCESS: {
			const {
				token,
				user: { first_name, last_name, role, id, host, guest, confirm_policy, email }
			} = action.payload;
			return {
				...state,
				token,
				email,
				id,
				first_name,
				last_name,
				role,
				host,
				guest,
				guest_registration_status: guest ? guest.registration_status : null,
				errorKeys: [],
				confirm_policy
			};
		}
		case authActionTypes.UPDATE_USER_SUCCESS: {
			const { email, first_name, last_name } = action.payload;
			return {
				...state,
				email,
				first_name,
				last_name
			};
		}
		case authActionTypes.LOGIN_FAILURE:
		case authActionTypes.REGISTER_USER_FAILURE:
		case authActionTypes.UPDATE_USER_FAILURE:
			return {
				...state,
				errorKeys: action.payload
			};
		case authActionTypes.REGISTER_GUEST_SUCCESS: {
			return {
				...state,
				...action.payload,
				guest: {
					id: action.payload.guest,
					registration_status: action.payload.registartion_status
				},
				host: null
			};
		}
		case authActionTypes.REGISTER_HOST_SUCCESS: {
			return {
				...state,
				...action.payload,
				host: {
					id: action.payload.host,
					registration_status: action.payload.registartion_status
				},
				guest: null
			};
		}
		case authActionTypes.CHANGE_HOST_TO_GUEST_SUCCESS: {
			return {
				...state,
				role: 'guest'
			};
		}
		case authActionTypes.CHANGE_GUEST_TO_HOST_SUCCESS: {
			return {
				...state,
				role: 'host'
			};
		}
		case authActionTypes.LOGOUT:
			return {
				confirm_policy: false,
				token: null,
				errorKeys: []
			};
		case authActionTypes.CONFIRM_POLICY_SUCCESS: {
			const { confirm_policy } = action.payload;
			return {
				...state,
				confirm_policy
			};
		}
		case authActionTypes.SEND_LOGIN_SMS_CODE_SUCCESS: {
			return {
				...state,
				phoneNumber: action.payload
			};
		}
		case authActionTypes.SEND_LOGIN_SMS_CODE_FAILURE: {
			return {
				...state,
				errorKeys: action.payload
			};
		}
		case authActionTypes.LOGIN_CODE_VERIFICATION_FAILURE: {
			return {
				...state,
				errorKeys: action.payload.request.status
			};
		}
		case authActionTypes.OPEN_CHANGE_ROLES_MODAL: {
			return {
				...state,
				isShowModal: action.payload
			};
		}
		case authActionTypes.CLOSE_CHANGE_ROLES_MODAL: {
			return {
				...state,
				isShowModal: action.payload
			};
		}
		case authActionTypes.ORIGIN_REQUEST_SUCCESS:
			const { country } = action.payload;
			return {
				...state,
				isFromIL: country ? country === 'IL' : true
			};
		default:
			return state;
	}
};

const persistConfig = {
	key: 'auth',
	storage: storage,
	blacklist: ['errorKeys']
};

export default persistReducer(persistConfig, authReducer);
