import { css } from "styled-components/macro";
import { inputBorderRadius } from "./sizes";
import { bodyFont } from "./fonts";
import { body14 } from "./typography";

export const input = css`
  width: 100%;
  outline: none;
  resize: none;
  font-family: ${bodyFont};
  outline: 'none';
`;

export const newSalonInput = css`
  width: 100%;
  padding: 9px;
  outline: none;
  resize: none;
  font-family: ${bodyFont};
  ${body14};
`;
