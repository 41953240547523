export enum adminActionTypes {
  GET_INIT_DATA = "GET_INIT_DATA",
  GET_INIT_DATA_START = "GET_INIT_DATA_START",
  GET_INIT_DATA_SUCCESS = "GET_INIT_DATA_SUCCESS",

  GET_APP_DATA = "GET_APP_DATA",
  GET_APP_DATA_START = "GET_APP_DATA_START",
  GET_APP_DATA_SUCCESS = "GET_APP_DATA_SUCCESS",

  GET_CITIES = "GET_CITIES",
  GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS",
  GET_CITIES_FAILURE = "GET_CITIES_FAILURE",

  GET_COMMUNITIES = "GET_COMMUNITIES",
  GET_COMMUNITIES_SUCCESS = "GET_COMMUNITIES_SUCCESS",
  GET_COMMUNITIES_FAILURE = "GET_COMMUNITIES_FAILURE",

  GET_LANGUAGES = "GET_LANGUAGES",
  GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS",
  GET_LANGUAGES_FAILURE = "GET_LANGUAGES_FAILURE",

  SET_NUMBER_OF_ACTIVE_FILTERS = "SET_NUMBER_OF_ACTIVE_FILTERS",

  CREATE_WITNESS = "CREATE_WITNESS",
  CREATE_WITNESS_SUCCESS = "CREATE_WITNESS_SUCCESS",
  CREATE_WITNESS_FAILURE = "CREATE_WITNESS_FAILURE",

  CREATE_WITNESS_DUPLICATE_VALIDATION = "CREATE_WITNESS_DUPLICATE_VALIDATION",
  CREATE_WITNESS_DUPLICATE_VALIDATION_SUCCESS = "CREATE_WITNESS_DUPLICATE_VALIDATION_SUCCESS",
  CREATE_WITNESS_DUPLICATE_VALIDATION_FAILURE = "CREATE_WITNESS_DUPLICATE_VALIDATION_FAILURE",

  GET_VOLUNTEERS_LIST_BY_ID = "GET_VOLUNTEERS_LIST_BY_ID",
  GET_VOLUNTEERS_LIST_BY_ID_SUCCESS = "GET_VOLUNTEERS_LIST_BY_ID_SUCCESS",

  SET_NEW_WITNESS_ID = "SET_NEW_WITNESS_ID",

  SET_IS_LOADING = "SET_IS_LOADING",

  GET_USER_CITY_ADDRESS = "GET_USER_CITY_ADDRESS",
  CLEAR_USER_CITY_ADDRESS = "CLEAR_USER_CITY_ADDRESS",
}
