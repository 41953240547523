import React, { useEffect, useState } from "react";
 import { Helmet } from "react-helmet";
import useLanguage from "../useLanguage";

export const GoogleMapScript: React.FC = () => {
  const [url, setURL] = useState<string>("");
  const [lang, _setLang] = useLanguage();

  useEffect(() => {
    if (lang) {
      if (typeof google !== "undefined") {
        delete google.maps;
      }
      setURL(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY || "AIzaSyCSVSuUVhz6XBgyQ3R0W3NTuzl8p05N8gI"}&libraries=places&language=${lang}&callback=onMapLoad`
      );
    }
  }, [lang]);

  if (!url) return null;
  return (
    <>
      <Helmet>
        <script type="text/javascript" src={url} />
      </Helmet>
    </>
  );
};
