import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "styled-components/macro";

interface IToolTip {
  title: string;
  element?: JSX.Element;
  isPosition?: string;
  top?: string;
  isMargin?: boolean;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    direction: "ltr",
    position: "relative",
  },
}))(Tooltip);

const CustomizedTooltip: React.FC<IToolTip> = ({
  title,
  element,
  isPosition,
  top,
  isMargin,
}) => {
  return (
    <SpanIconTop top={top} isPosition={isPosition} isMargin={isMargin}>
      <LightTooltip enterTouchDelay={50} title={title}>
        {element}
      </LightTooltip>
    </SpanIconTop>
  );
};

const SpanIconTop = styled.span<{
  isPosition: string;
  top?: string;
  isMargin?: boolean;
}>`
  position: ${(props) => props.isPosition};
  top: ${({ top }) => (top ? top : "-12px")};
  left: 2px;
  margin: ${({ isMargin }) => (isMargin ? "0 0.5rem" : "0")};
`;

export default CustomizedTooltip;
