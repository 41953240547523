import { hostsActionTypes } from "../actions/hosts/hosts.types";
import { IHostState } from "../../interfaces/hosts";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authActionTypes } from "../actions/auth/auth.types";

const initialState: IHostState = {
  hostProfile: null,
  hostSalonsList: null,
  hostSalons: null,
  hostId: null,
  hostsList: [],
  hostsFullList: [],
  hostsCountAllPages: 0,
  totalHostsAmountNoFilters: 0,
  nextFilters: null,
  previousFilters: null,
  isGetHostsListLoading: false,
  hostRegistrationDetails: null,
};

const hostsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case hostsActionTypes.GET_HOST_PROFILE_SUCCESS:
    case authActionTypes.CHANGE_GUEST_TO_HOST_SUCCESS: {
      const { id, phone } = action.payload;
      return {
        ...state,
        hostProfile: { ...action.payload, phone: phone ? phone : "" },
        hostId: id,
      };
    }
    case hostsActionTypes.GET_HOST_LIST_LOADING: {
      return { ...state, isGetHostsListLoading: true };
    }
    case hostsActionTypes.DELETE_SALON: {
      let newSalons = {};
      Object.keys(state.hostSalonsList).forEach((key)=>{
        newSalons[key] = state.hostSalonsList[key].filter((host) => host.id !== action.payload)
      })
      return {
        ...state,
        hostSalonsList: newSalons,
        hostsCountAllPages:
        state.hostsCountAllPages -
        // @ts-ignore
          state.hostsList.filter((host) => host.id === action.payload).length,
        totalHostsAmountNoFilters:
        state.totalHostsAmountNoFilters -
        // @ts-ignore
          state.hostsList.filter((host) => host.id === action.payload).length,
      };
    }
    case hostsActionTypes.GET_ALL_HOSTS_SUCCESS: {
      const {
        hostsList,
        hostsCountAllPages,
        totalHostsAmountNoFilters,
        nextFilters,
        previousFilters,
      } = action.payload;
      return {
        ...state,
        hostsList,
        hostsCountAllPages,
        totalHostsAmountNoFilters,
        nextFilters,
        previousFilters,
        isGetHostsListLoading: false,
      };
    }
    case hostsActionTypes.GET_ALL_HOSTS_EXPORT_SUCCSSES: {
      return {
        ...state,
        hostsFullList: action.payload,
      };
    }
    case hostsActionTypes.UPDATE_HOST_PROFILE_SUCCESS: {
      const hostProfile = action.payload;
      return { ...state, hostProfile };
    }
    case hostsActionTypes.GET_HOST_SALONS_LIST_SUCCESS: {
      const hostSalonsList = action.payload;
      return { ...state, hostSalonsList };
    }
    case hostsActionTypes.CREATE_HOST_SUCCESS:
      return {
        ...state,
        hostId: action.payload,
      };
    case hostsActionTypes.GET_HOST_SALONS_SUCCESS:
      return {
        ...state,
        hostSalons: action.payload,
      };
    case hostsActionTypes.RESET_HOST_ID:
      return {
        ...state,
        hostId: null,
      };
    case hostsActionTypes.CLEAR_HOST_SALON_LIST:
      return {
        ...state,
        hostProfile: {},
        hostSalonsList: {},
        hostSalons: {},
      };
    case hostsActionTypes.DELETE_HOST_SALON_BY_ID: {
      const { hostSalonId } = action.payload;

      const updateList = state.hostSalonsList?.id === hostSalonId;

      if (updateList)
        // state.hostSalonsList[salonYear]?.splice(updateList, 1);
      if (state.hostSalonsList)
        delete state.hostSalonsList;

      return { ...state, hostSalonsList: { ...state.hostSalonsList } };
    }
    case authActionTypes.CHANGE_GUEST_TO_HOST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case authActionTypes.SAVE_HOST_REGISTRATION_DETAILS: {
      return {
        ...state,
        hostRegistrationDetails: {
          ...state.hostRegistrationDetails,
          ...action.payload,
        },
      };
    }
    case hostsActionTypes.SAVE_HOST_PERSONAL_DETAILS_EDIT: {
      return {
        ...state,
        hostPersonalDetailsEdit: action.payload,
      };
    }
    case authActionTypes.CLEAR_HOST_REGISTRATION_DETAILS: {
      return {
        ...state,
        hostRegistrationDetails: [],
      };
    }
    default:
      return state;
  }
};

const persistConfig = {
  key: "hosts",
  storage: storage,
  whitelist: ["hostId", "hostRegistrationDetails"],
};

export default persistReducer(persistConfig, hostsReducer);
