const createParamsRequest = (filterParams, ...rest) => {
  const params = {};
  const [pageNumber, action] = rest;

  // convers url parameters into a js object
  const searchParams = new URLSearchParams(filterParams);

  for (let pair_array of searchParams) {
    const [key, value] = pair_array;
    if (!params.hasOwnProperty(key)) {
      params[key] = [value];
    } else {
      params[key].push(value);
    }
  }
  params["page"] = pageNumber + 1;
  action(params);
  return;
};

export const createTableFiltersRequest = (
  nextFilters,
  previousFilters,
  ...rest
) => {
  if (nextFilters === null && previousFilters === null) return;

  if (nextFilters !== null) {
    createParamsRequest(nextFilters, ...rest);
  }
  if (previousFilters !== null) {
    createParamsRequest(previousFilters, ...rest);
  }
};

const splitString = (str) => (str ? str.split("/") : null);
const getFilters = (arr) => (arr ? arr[arr.length - 1] : arr);

export const getFiltersStrings = (next, previous) => {
  const next_array = splitString(next);
  const previous_array = splitString(previous);
  return {
    nextFilters: getFilters(next_array),
    previousFilters: getFilters(previous_array),
  };
};
